import './login-fields.scss';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import Checkbox from 'antd/es/checkbox';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Input } from '@/components/Shared/ui';
import WarningIcon from '@/media/icons/warning-fill.svg?react';
import CloseIcon from '@/media/icons/x.svg?react';
import { useAppDispatch, useAppSelector } from '@/store';
import { resetLoginError, setLoginWarning } from '@/store/auth.slice';

import { IFormInput } from '../../types/form-types';

const LoginFields: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loginError, loginWarning } = useAppSelector((state) => state.auth);

  const { control } = useFormContext<IFormInput>();

  const [showPassword, setShowPassword] = useState(false);

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Controller
        control={control}
        name="email"
        rules={{
          required: 'Пожалуйста, заполните поле',
          minLength: {
            value: 1,
            message: 'Пожалуйста, заполните поле'
          },
          validate: (value) => {
            if (!value.match(/.+@.+\..+/)) return 'Невалидный email адрес';
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            type="email"
            label="Email"
            value={field.value ?? ''}
            onChange={(e) => {
              dispatch(resetLoginError());
              field.onChange(e);
            }}
            error={error?.message}
            classes={{ root: 'login-fields__field' }}
            isRequired
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{
          required: 'Пожалуйста, заполните поле',
          minLength: {
            value: 1,
            message: 'Пожалуйста, заполните поле'
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            type={showPassword ? 'text' : 'password'}
            label="Введите пароль"
            value={field.value ?? ''}
            onChange={(e) => {
              dispatch(resetLoginError());
              field.onChange(e);
            }}
            error={error?.message}
            classes={{ root: 'login-fields__field' }}
            isRequired
            endContent={
              <button
                type="button"
                className="login-fields__password-toggle"
                onClick={handleToggle}
              >
                {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </button>
            }
          />
        )}
      />
      <div className="login-fields__remember-wrapper">
        <Controller
          control={control}
          name="isRemember"
          render={({ field }) => (
            <Checkbox
              className="login-fields__remember"
              checked={field.value}
              onChange={field.onChange}
            >
              <span className="login-fields__remember-text">
                Запомнить меня
              </span>
            </Checkbox>
          )}
        />
      </div>

      {loginError && (
        <div className="login-fields__alert login-fields__alert--error">
          <WarningIcon />
          <p className="login-fields__alert-text">{loginError}</p>
          <button
            type="button"
            className="login-fields__alert-cross"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(resetLoginError());
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      {loginWarning && (
        <div className="login-fields__alert login-fields__alert--warning">
          <WarningIcon />
          <p className="login-fields__alert-text">{loginWarning}</p>
          <button
            type="button"
            className="login-fields__alert-cross"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setLoginWarning(''));
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </>
  );
};

export default LoginFields;
