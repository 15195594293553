import './timer.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import SmsMessageIcon from '@/media/icons/sms-message.svg?react';
import WhatsappIcon from '@/media/icons/whatsapp.svg?react';

interface IProps {
  timerStarted: boolean;
  onRetry: (type: 'whatsapp' | 'phone') => void;
  hasWhatsApp: boolean;
  isContinuing: boolean;
}

const Timer: React.FC<IProps> = ({
  timerStarted,
  onRetry,
  hasWhatsApp,
  isContinuing
}) => {
  const interval = useRef<NodeJS.Timeout | undefined>(undefined);
  const [timer, setTimer] = useState(59);
  const [canStartTimer, setCanStartTimer] = useState(isContinuing);

  const timeoutBody = useCallback(() => {
    if (timer > 0) setTimer((prevState) => prevState - 1);
    else clearInterval(interval.current);
  }, [timer]);

  const handleRetry = (type: 'whatsapp' | 'phone') => () => {
    setTimer(59);
    onRetry(type);
    setCanStartTimer(true);
  };

  useEffect(() => {
    if (timerStarted) interval.current = setInterval(timeoutBody, 1000);
    return () => {
      clearInterval(interval.current);
    };
  }, [timerStarted, timer, timeoutBody]);

  return (
    <>
      {timer === 0 || !canStartTimer ? (
        <>
          {hasWhatsApp && (
            <button
              type="button"
              className="timer__button"
              onClick={handleRetry('whatsapp')}
            >
              Отправить код еще раз <WhatsappIcon />
            </button>
          )}
          <button
            type="button"
            className="timer__button"
            onClick={handleRetry('phone')}
          >
            Отправить код через SMS <SmsMessageIcon />
          </button>
        </>
      ) : (
        <span className="timer__seconds">
          Отправить код повторно
          {timer ? ` через 00:${timer > 9 ? timer : `0${timer}`}` : ''}
        </span>
      )}
    </>
  );
};

export default Timer;
