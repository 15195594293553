import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  TLeadsOptions,
  TResponseLeads,
  TSingleLead
} from '@/components/App/Leads/types.ts';

import { apiConnector } from '../integrations/api.connector';

interface LeadsState {
  newLeadsCount: number;
  leads: TResponseLeads | null;
  selectedLead: TSingleLead | null;
  loading: boolean;
  error: string | null;
}

const initialState: LeadsState = {
  newLeadsCount: 0,
  leads: {
    data: [],
    total: 0
  },
  selectedLead: null,
  loading: true,
  error: null
};

export const getLeads = createAsyncThunk(
  'leads/getLeads',
  async (data: TLeadsOptions) => {
    return await apiConnector.getLeads(data);
  }
);

export const startBadgeLeads = createAsyncThunk('leads/badge', async () => {
  return await apiConnector.startBadgeLeads();
});

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    onLeadsInit(state) {
      return { ...initialState, newLeadsCount: state.newLeadsCount };
    },
    clearSelectedLead(state) {
      state.selectedLead = null;
    },
    setBadgeNewLeadsCount: (state, action: PayloadAction<number>) => {
      state.newLeadsCount = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getLeads.fulfilled,
        (state, action: PayloadAction<TResponseLeads>) => {
          state.loading = false;
          if (action.payload) {
            state.leads = action?.payload;
          }
        }
      )
      .addCase(getLeads.rejected, (state, action: PayloadAction<unknown>) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(
        startBadgeLeads.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.newLeadsCount = action.payload || 0;
        }
      )
      .addCase(startBadgeLeads.rejected, (state) => {
        state.newLeadsCount = 0;
      });
  }
});

export const { onLeadsInit, setBadgeNewLeadsCount } = leadsSlice.actions;
