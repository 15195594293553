import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiConnector } from '@/integrations/api.connector';
import { insuranceMapperItem } from '@/services/mappers/insuranceMapperItem';
import { insuranceMapperResp } from '@/services/mappers/insuranceMapperResp';
import {
  GetInsuranceTableBody,
  TNewInsuranceServer
} from '@/types/insuranseServer';
import { TNewInsuranceDto } from '@/types/newInsurance';
import { TOrderInsuranceStore } from '@/types/store';

const initialState: TOrderInsuranceStore = {
  insurances: {
    data: [],
    total: 0,
    pageCount: 0
  },
  description: {
    data: ''
  },
  uuid: '',
  otpCode: '',
  otpValid: null,
  requestId: '',
  //clientCalcId: '',
  isLoading: false,
  productInsuranceInfo: {
    dateEndpolis: '',
    productType: null,
    paymentType: null,
    dateForm: '',
    payment: '',
    datepolis: null
  },

  personalInsuranceInfo: {
    adress: '',
    issuebdy: '',
    resident: null,
    email: '',
    numberdocument: '',
    dateDocument: '',
    placeBirth: '',
    validity: '',
    firstName: '',
    middleName: '',
    lastName: '',
    jobAddress: '',
    clientIin: '',
    clientPhone: '',
    dateBirth: '',
    sex: null,
    docType: null
  },
  autoInsuranceInfo: {
    dealer: '',
    priseAvto: '',
    tehNum: '',
    dateOfTechPasspord: '',
    numberGos: '',
    vinCode: '',
    markModelYear: {
      model: undefined,
      year: null
    }
  },
  insurance: {
    insuranseSumm: '',
    insuransePremium: ''
  },
  clientInsuranceInfo: {
    innInsurance: '',
    numberInsurance: ''
  }
};

export const createInsuranceOrder = createAsyncThunk(
  'ínsurance/create',

  async (data: TNewInsuranceDto) => {
    return await apiConnector.createInsuranceOrder(data);
  }
);

export const requestCreateOtp = createAsyncThunk(
  'insurances/createOTP',
  async (data: { iin: string; clientPhone: string }): Promise<any> => {
    try {
      return await apiConnector.requestCreateOtpInsurance(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);
export const validateInsuranceOTP = createAsyncThunk(
  'insurances/validateOTP',
  async (data: { uuid: string; otp: string }): Promise<any> => {
    try {
      const responseData = (await apiConnector.validateOTPInsurance(
        data
      )) as any;
      return responseData;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getOneInsuranceReq = createAsyncThunk(
  'insurances/findByInsuranceId',
  async (id: string) => {
    try {
      return (await apiConnector.getOneInsurace(id)) as any;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const filterInsurance = createAsyncThunk(
  'insurances/getFilteredInsurances',
  async (data: GetInsuranceTableBody) => {
    try {
      const response = await apiConnector.getFilterInsurance(data);
      return response;
    } catch (error: unknown) {
      throw Error((error as { message: string }).message);
    }
  }
);

export const getInsurancesByFilters = createAsyncThunk(
  'insurances/filters/getInsurancesByParams',
  async (data: {
    status_code?: string;
    dealer_uuid?: string;
    model_uuid?: string;
    brand_uuid?: string;
    date_from?: string;
    date_until?: string;
  }) => {
    try {
      const response = await apiConnector.getInsurancesByParams(
        data.status_code,
        data.dealer_uuid,
        data.model_uuid,
        data.brand_uuid,
        data.date_from,
        data.date_until
      );
      return response;
    } catch {
      throw new Error('Failed to fetch filtered requests');
    }
  }
);

export const orderInsuranceSlice = createSlice({
  name: 'orderInsuranceSlice',
  initialState,
  reducers: {
    onSetInsurancesStoreToInitial() {
      return initialState;
    },
    onSetClientInsuranceInfo(state, action) {
      state.clientInsuranceInfo = action.payload;
    },
    onSetRequestId(state, action: PayloadAction<string>) {
      state.requestId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createInsuranceOrder.fulfilled,
        (state, action: PayloadAction<TNewInsuranceServer>) => {
          state.isLoading = false;
          state.requestId = action.payload.uuid;
          const parsedData = insuranceMapperItem(action.payload);
          state.autoInsuranceInfo = parsedData.autoInsuranceInfo;
          state.productInsuranceInfo = parsedData.productInsuranceInfo;
          state.personalInsuranceInfo = parsedData.personalInsuranceInfo;
          state.insurance = parsedData.insurance;
          state.clientInsuranceInfo = parsedData.clientInsuranceInfo;
        }
      )
      .addCase(createInsuranceOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInsuranceOrder.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(requestCreateOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requestId = action.payload.request_uuid;
      })
      .addCase(requestCreateOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestCreateOtp.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(validateInsuranceOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.otpValid = action.payload?.result || false;
        state.otpCode = action.payload?.otpCode || '';
      })
      .addCase(validateInsuranceOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateInsuranceOTP.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(filterInsurance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.insurances = insuranceMapperResp(action.payload);
      })
      .addCase(filterInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(filterInsurance.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getOneInsuranceReq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requestId = action.payload.uuid;
        try {
          const description = JSON.parse(action.payload.description);
          state.description.data =
            typeof description.data === 'string' ? description.data : '';
        } catch (error) {
          console.error('Ошибка парсинга description:', error);
          state.description.data = '';
        }
        const parsedData = insuranceMapperItem(action.payload);
        state.autoInsuranceInfo = parsedData.autoInsuranceInfo;
        state.productInsuranceInfo = parsedData.productInsuranceInfo;
        state.personalInsuranceInfo = parsedData.personalInsuranceInfo;
        state.insurance = parsedData.insurance;
        state.clientInsuranceInfo = parsedData.clientInsuranceInfo;
      })
      .addCase(getOneInsuranceReq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneInsuranceReq.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getInsurancesByFilters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.insurances = insuranceMapperResp(action.payload);
      })
      .addCase(getInsurancesByFilters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInsurancesByFilters.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {
  onSetClientInsuranceInfo,
  onSetInsurancesStoreToInitial,
  onSetRequestId
} = orderInsuranceSlice.actions;
