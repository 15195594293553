import './NotificationItem.scss';

import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

import { TNotificationServerResponse } from '@/types/notifications';

type TNotificationItemProps = {
  notification: TNotificationServerResponse;
};

const NotificationItem: FC<TNotificationItemProps> = ({ notification }) => {
  return (
    <div className="notification-item">
      <Button>
        <CloseOutlined />
      </Button>
      <p>Банк: {notification.bank}</p>
      <p>Ext UUID: {notification.ext_uuid}</p>
      <p>Execution name: {notification.execution_name}</p>
      <p className="notification-item__message">
        Сообщение: {notification.description}
      </p>
      <p>
        Дата: {dayjs(notification.create_date).format('DD.MM.YYYY HH:MM:SS')}
      </p>
    </div>
  );
};
export default NotificationItem;
