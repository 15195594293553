import {
  TAdminModule,
  TModuleWithRoles,
  TUpdateAdminModule
} from '@/types/modulesAdmin';

import { ApiConnector } from '../api.connector';

export class ModulesApi extends ApiConnector {
  public async getAdminAllModules(
    limit?: number,
    offset?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TAdminModule[];
  }> {
    return this.call({
      path: 'admin/module_management',
      params: {
        limit,
        offset,
        searchTerm
      }
    });
  }

  public async updateAdminModules(moduleId: string, data: TUpdateAdminModule) {
    return this.call({
      path: `admin/module_management/${moduleId}`,
      body: data,
      method: 'put'
    });
  }

  public async createAdminModules(data: TUpdateAdminModule) {
    return this.call({
      path: `admin/module_management`,
      body: data,
      method: 'post'
    });
  }

  public async getAdminModuleById(uuid: string): Promise<TModuleWithRoles> {
    return this.call({ path: `admin/module_management/${uuid}` });
  }
}

export const modulesApi = new ModulesApi();
