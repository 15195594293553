import './adaptive-form-steps.scss';

interface IProps {
  currentStep: number;
  textCenter?: boolean;
  steps: Array<{
    titleMobile: string;
    icon: JSX.Element;
  }>;
  onChange: (step: number) => void;

  isNextClickable?: boolean;
}

const AdaptiveFormSteps: React.FC<IProps> = ({
  currentStep,
  steps,
  textCenter,
  onChange
}) => {
  return (
    <div
      className="adaptive-steps"
      //@ts-ignore
      style={{ '--step-count': steps.length, '--current-step': currentStep }}
    >
      <div
        className={`adaptive-steps__body ${textCenter ? 'adaptive-steps__body--text-center' : ''}`}
      >
        {steps.map((step, i) => (
          <div
            className={`adaptive-steps__step ${
              currentStep === i + 1 ? 'adaptive-steps__step--active' : ''
            } ${currentStep > i + 1 ? 'adaptive-steps__step--passed' : ''}`}
            key={step.titleMobile}
            onClick={() => onChange(i)}
          >
            {step.icon && (
              <span className="adaptive-steps__icon">{step.icon}</span>
            )}
            <p
              className={`adaptive-steps__text ${
                currentStep < i + 1 ? 'adaptive-steps__text--gray' : ''
              }`}
            >
              {step.titleMobile}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdaptiveFormSteps;
