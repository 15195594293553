import './NotificationSidebar.scss';

import { Button, Drawer } from 'antd';

import { useAppDispatch, useAppSelector } from '@/store';
import { onToggleShowNotifications } from '@/store/notifications.slice';

import NotificationItem from './NotificationItem';

const NotificationsSideBar = () => {
  const { notifications, showNotifications } = useAppSelector(
    (state) => state.notifications
  );
  const dispatch = useAppDispatch();

  return (
    <div className="notifications-sidebar">
      <Drawer
        title={'Уведомления'}
        closable={false}
        open={showNotifications}
        onClose={() => dispatch(onToggleShowNotifications())}
      >
        <Button className="notifications-sidebar__delete">
          Удалить все уведомления
        </Button>

        {notifications.length
          ? notifications.map((notification) => (
              <NotificationItem
                notification={notification}
                key={notification.uuid}
              />
            ))
          : null}
      </Drawer>
    </div>
  );
};

export default NotificationsSideBar;
