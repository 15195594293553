import './date-range-picker-field.scss';

import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { BaseInfo } from 'rc-picker/lib/interface';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import CalendarIcon from '@/media/icons/calendar.svg?react';
import CrossIcon from '@/media/icons/cross.svg?react';

import MaskedInput, { MaskedInputInterface } from '../masked-input';
import OutsideClickHandler from '../outside-click-handler';

dayjs.extend(customParseFormat);

type ChangedMaskedInputInterface = Omit<
  MaskedInputInterface,
  'value' | 'onChange' | 'mask'
>;

interface IProps extends ChangedMaskedInputInterface {
  value?: (string | undefined)[];
  onChange?: (value: (string | undefined)[]) => void;
  isFrozen?: boolean;
  isClearable?: boolean;
}
const { RangePicker } = DatePicker;

const DateRangePickerField = forwardRef<HTMLInputElement, IProps>(
  (
    { name, value, onChange, isFrozen, isClearable = false, ...rest },
    forwardRef
  ) => {
    const calendar = useRef<HTMLDivElement>(null);

    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string[]>(['', '']);
    const clearButtonRef = useRef<HTMLDivElement>(null);

    const dates = useMemo(() => {
      if (!value) return [null, null];
      const [startDate, endDate] = value;
      return [
        dayjs(startDate).isValid() ? dayjs(startDate) : null,
        dayjs(endDate).isValid() ? dayjs(endDate) : null
      ];
    }, [value]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const dates = newValue.split(' - ');
      onChange?.(
        dates.map((item) =>
          dayjs(item, 'DD.MM.YYYY').isValid()
            ? dayjs(item, 'DD.MM.YYYY')?.format('YYYY-MM-DD')
            : ''
        )
      );
      setInputValue(dates);
    };

    const handleCalendarChange = (
      dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
      _: [string, string],
      info: BaseInfo
    ) => {
      if (!dates) return;
      const isValueValid = value?.every((item) => dayjs(item)?.isValid());
      if (info.range === 'start' && isValueValid) {
        dates = [dates[0], null];
      } else if (info.range === 'end' && isValueValid) {
        dates = [null, dates[1]];
      }

      onChange?.(
        dates?.map((item) => {
          const date = dayjs(item, 'DD.MM.YYYY');
          if (date.isValid()) return date?.format('YYYY-MM-DD');
          else return '';
        })
      );
      const isDatesValid = dates?.every((item) => dayjs(item)?.isValid());
      if (isDatesValid) setFocused(false);
    };

    useEffect(() => {
      if (value && dayjs(value[0]).isValid() && dayjs(value[1]).isValid()) {
        setInputValue(value.map((item) => dayjs(item).format('DD.MM.YYYY')));
      }
    }, [value]);

    const handleClear = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.stopPropagation();
      onChange?.([]);
    };

    return (
      <OutsideClickHandler
        onOutsideClick={(element) => {
          if (!calendar.current?.contains(element)) setFocused(false);
        }}
      >
        <div className="date-range-picker-field">
          <MaskedInput
            {...rest}
            type="tel"
            ref={forwardRef}
            value={inputValue[0] ? inputValue.join(' - ') : ''}
            onChange={handleInputChange}
            endContent={
              <div className="date-range-picker-field__end-content">
                {isClearable && (
                  <div
                    ref={clearButtonRef}
                    onClick={handleClear}
                    className="date-range-picker-field__clear"
                  >
                    <CrossIcon className="date-range-picker-field__clear-icon" />
                  </div>
                )}
                <span className="date-range-picker-field__icon">
                  <CalendarIcon />
                </span>
              </div>
            }
            onClick={(e) => {
              if (!isFrozen) setFocused((prev) => !prev);
              rest.onClick?.(e);
            }}
            mask="__.__.____ - __.__.____"
            replacement={rest.replacement || { _: /\d/ }}
            autoComplete="off"
            isFrozen={isFrozen}
          />

          <div ref={calendar}>
            <RangePicker
              picker="date"
              value={dates as [Dayjs | null, Dayjs | null]}
              onCalendarChange={handleCalendarChange}
              getPopupContainer={() => calendar.current || document.body}
              className="date-range-picker-field__calendar"
              popupClassName={`date-range-picker-field__calendar-dropdown date-range-picker-${name}`}
              format={'DD.MM.YYYY'}
              open={focused}
            />
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
);

DateRangePickerField.displayName = 'DateRangePickerField';

export default DateRangePickerField;
