import { TDealer, TDealerDto, TDealerResponse } from '@/types/dealer.type';

import { ApiConnector } from '../api.connector';

export class DealersApi extends ApiConnector {
  public getAdminAllDealers(
    limit?: number,
    page?: number,
    searchTerm?: string,
    city?: string,
    isActive?: boolean,
    hasCommercial?: boolean
  ): Promise<{
    total: number;
    dealers: TDealer[];
  }> {
    return this.call({
      path: 'admin/dealers',
      params: {
        limit,
        page,
        searchTerm,
        city,
        isActive,
        hasCommercial
      }
    });
  }

  public getAdminDealerByParam(param: string | number): Promise<TDealer[]> {
    return this.call({ path: `admin/dealers/${param}/findByParam` });
  }

  public createAdminDealer(body: TDealerDto) {
    return this.call({ path: `admin/dealers`, method: 'post', body });
  }

  public updateAdminDealer(body: TDealer) {
    return this.call({
      path: `admin/dealers/${body.uuid}`,
      method: 'patch',
      body
    });
  }

  public getAdminById(uuid: string): Promise<TDealerResponse> {
    return this.call({ path: `admin/dealers/${uuid}` });
  }
}

export const dealersApi = new DealersApi();
