import { useEffect, useRef, useState } from 'react';

import { IMultiselectOption, MULTISELECT_GROUP } from '../multiselect';

const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  PAGE_DOWN: 34,
  ESCAPE: 27,
  PAGE_UP: 33,
  ENTER: 13
};

interface IProps {
  selectedValues?: IMultiselectOption[];
  onChange: (
    value: IMultiselectOption,
    selectedValues: IMultiselectOption[]
  ) => void;
  defaultOptions: IMultiselectOption[];
  setFocused: (value: boolean) => void;
}

const SEARCH_HEIGHT = 40;

const useSelect = ({
  selectedValues,
  onChange,
  defaultOptions,
  setFocused
}: IProps) => {
  const listRef = useRef<HTMLUListElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (listRef.current && selectedIndex !== -1) {
      listRef.current.scrollTop =
        (listRef.current.children[selectedIndex] as HTMLElement)?.offsetTop -
        listRef.current.children[selectedIndex]?.clientHeight -
        SEARCH_HEIGHT;
    }
  }, [selectedIndex]);

  function selectOption(index: number) {
    if (index > -1) {
      const isValueExists = selectedValues?.find(
        (item) => item.value === defaultOptions[index].value
      );
      const list = selectedValues?.filter(
        (item) => item.value !== isValueExists?.value
      );

      const newSelectedValues = list ?? [];
      if (!isValueExists) newSelectedValues.push(defaultOptions[index]);
      onChange(defaultOptions[index], newSelectedValues);
    }
  }

  function scrollUp() {
    if (selectedIndex > 1) {
      const nextElement = defaultOptions[selectedIndex - 1];
      const nextIndex = nextElement?.value === MULTISELECT_GROUP ? 2 : 1;
      setSelectedIndex(selectedIndex - nextIndex);
    } else if (selectedIndex === 1) {
      const nextElement = defaultOptions[0];
      const nextIndex =
        nextElement?.value === MULTISELECT_GROUP
          ? defaultOptions.length - 1
          : 0;
      setSelectedIndex(nextIndex);
    } else if (selectedIndex === -1 || selectedIndex === 0) {
      setSelectedIndex(defaultOptions.length - 1);
    }
  }

  function scrollDown() {
    if (selectedIndex < defaultOptions.length - 1 || selectedIndex === -1) {
      const nextElement = defaultOptions[selectedIndex + 1];
      const nextIndex = nextElement?.value === MULTISELECT_GROUP ? 2 : 1;
      setSelectedIndex(selectedIndex + nextIndex);
    } else if (selectedIndex === defaultOptions.length - 1) {
      const nextElement = defaultOptions[0];
      const nextIndex = nextElement?.value === MULTISELECT_GROUP ? 1 : 0;
      setSelectedIndex(nextIndex);
    }
  }

  function pageDown() {
    const nextElement = defaultOptions[defaultOptions.length - 1];
    const nextIndex =
      nextElement?.value === MULTISELECT_GROUP
        ? defaultOptions.length - 2
        : defaultOptions.length - 1;
    setSelectedIndex(nextIndex);
  }

  function pageUp() {
    const nextElement = defaultOptions[0];
    const nextIndex = nextElement?.value === MULTISELECT_GROUP ? 1 : 0;
    setSelectedIndex(nextIndex);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const keyOperation = {
      [KEY_CODES.DOWN]: scrollDown,
      [KEY_CODES.UP]: scrollUp,
      [KEY_CODES.ENTER]: () => selectOption(selectedIndex),
      [KEY_CODES.ESCAPE]: () => setFocused(false),
      [KEY_CODES.PAGE_DOWN]: pageDown,
      [KEY_CODES.PAGE_UP]: pageUp
    };
    if (keyOperation[e.keyCode]) {
      keyOperation[e.keyCode]();
    } else {
      setSelectedIndex(-1);
    }
  }

  return {
    bindOption: {
      onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const nodes = Array.from(listRef.current?.children || []);
        // @ts-ignore
        selectOption(nodes.indexOf(e.target.closest('li')));
      }
    },
    bindInput: {
      value:
        selectedValues
          ?.map((item) => item.label[0].toUpperCase() + item.label.slice(1))
          .join(', ') || '',
      onKeyDown
    },
    bindOptions: {
      ref: listRef
    },
    selectedIndex
  };
};

export default useSelect;
