import DisableDevtool from 'disable-devtool';
import { jwtDecode } from 'jwt-decode';
import React, { lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Litro } from '@/components/App/LitroPnd/Litro';
import { LitroForm } from '@/components/App/LitroPnd/LitroForm/LitroForm';
import BiometricScan from '@/components/BiometricScan/BiometricScan';
import { useAppDispatch, useAppSelector } from '@/store';

import { AuthModal } from './components/Auth/login';
import { Layout } from './components/Layout/Layout';
import CheckAuth from './components/Shared/routes/check-auth';
import { ProtectedRoute } from './components/Shared/routes/private-route';
import { ADMIN_ROUTES, ROUTES } from './components/Shared/routes/routes';
import SuspenseRoute from './components/Shared/routes/suspense-route';
import { setNavigate } from './services/helpers/navigate-helper';
import { getUserToken } from './services/user-hash';
import { startBadgeLeads } from './store/leads.slice';
import { startNotification } from './store/notifications.slice';

const LoginPage = lazy(() => import('./components/Auth/login/login'));
const ResetPassword = lazy(() => import('./components/Auth/ResetPassword'));
const NotFound = lazy(() => import('./components/Layout/errors/404'));

const AdminRoutes = lazy(() => import('./components/AdminPanel/admin-routes'));
const MainPage = lazy(() => import('./components/App/main-page/main-page'));
const Personal = lazy(() => import('./components/App/personal/personal'));
const FAQ = lazy(() => import('./components/App/faq/main/faq'));
const FAQTemplate = lazy(
  () => import('./components/App/faq/faq-template/faq-template')
);

const AutoCredit = lazy(() => import('./components/App/AutoCred/auto-credit'));
const NewOrder = lazy(() => import('./components/App/NewOrder/NewOrder'));
const ExistedOrder = lazy(
  () => import('./components/App/NewOrder/ExistedOrder')
);
const Calculator = lazy(() => import('./components/App/calculator/calculator'));

const Insurance = lazy(() => import('./components/App/Insurance/insurance'));
const CreateInsurance = lazy(
  () => import('./components/App/Insurance/insurance-order/create-insurance')
);
const InsuranceOrder = lazy(
  () => import('./components/App/Insurance/insurance-order/insurance-order')
);

const Clients = lazy(
  () => import('./components/App/clients/clients-list/clients')
);
const ClientInfo = lazy(
  () => import('./components/App/clients/client-info/client-info')
);

const Leasing = lazy(() => import('./components/App/Leasing/Leasing'));
const CreateLeasing = lazy(
  () => import('./components/App/NewLeasing/create-leasing/create-leasing')
);
const ExistedLeasing = lazy(
  () => import('./components/App/NewLeasing/create-leasing/existed-leasing')
);

const Reports = lazy(() => import('./components/App/Reports'));
const Approvement = lazy(
  () => import('./components/App/Approvement/approvement')
);

const PosCredit = lazy(
  () => import('./components/App/PosCred/PosCreds/pos-creds')
);
const PosCreditApplication = lazy(
  () =>
    import('./components/App/PosCred/PosCredApclication/pos-cred-application')
);
const Leads = lazy(() => import('./components/App/Leads/page'));
const LeadsUpdate = lazy(() => import('./components/App/Leads/Update/page'));
const ACCEPTED_ROUTES = {
  main: <Route key={'main'} path={ROUTES.home} element={<MainPage />} />,
  'auto-credit': (
    <React.Fragment key={'auto-credit'}>
      <Route path={ROUTES.autoCredit} element={<AutoCredit />} />
      <Route path={ROUTES.autoCreditCreate} element={<NewOrder />} />
      <Route path={ROUTES.autoCreditRequest()} element={<ExistedOrder />} />
      <Route path={ROUTES.calculator} element={<Calculator />} />
    </React.Fragment>
  ),
  insurances: (
    <React.Fragment key={'insurances'}>
      <Route path={ROUTES.insurances} element={<Insurance />} />
      <Route path={ROUTES.insurancesCreate} element={<CreateInsurance />} />
      <Route path={ROUTES.insurancesRequest()} element={<InsuranceOrder />} />
    </React.Fragment>
  ),
  leasing: (
    <React.Fragment key={'leasing'}>
      <Route path={ROUTES.leasing} element={<Leasing />} />
      <Route path={ROUTES.leasingCreate} element={<CreateLeasing />} />
      <Route path={ROUTES.leasingRequest()} element={<ExistedLeasing />} />
    </React.Fragment>
  ),
  'pos-credit': (
    <React.Fragment key={'pos-credit'}>
      <Route path={ROUTES.posCredit} element={<PosCredit />} />
      <Route
        path={ROUTES.posCreditRequest()}
        element={<PosCreditApplication />}
      />
      <Route
        path={ROUTES.posCreditCreate()}
        element={<PosCreditApplication />}
      />
    </React.Fragment>
  ),
  clients: (
    <React.Fragment key={'clients'}>
      <Route path={ROUTES.clients} element={<Clients />} />
      <Route path={ROUTES.client()} element={<ClientInfo />} />
    </React.Fragment>
  ),
  reports: (
    <Route key={'reports'} path={ROUTES.reports} element={<Reports />} />
  ),
  leads: (
    <React.Fragment key={'leads'}>
      <Route path={ROUTES.leads} element={<Leads />} />
      <Route path={ROUTES.leadsUpdate()} element={<LeadsUpdate />} />
    </React.Fragment>
  )
};

export const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { isUserAuth } = useAppSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { menu } = useAppSelector((state) => state.userInfo);

  // Set global navigate to access from everywhere
  setNavigate(navigate);

  useEffect(() => {
    dispatch(startNotification());
    dispatch(startBadgeLeads());
  }, [location.pathname]);

  useEffect(() => {
    const accessToken = getUserToken().accessToken;
    if (accessToken) {
      const user: { actions: number[] } = jwtDecode(accessToken);
      if (user.actions.includes(0)) {
        setIsAdmin(true);
      } else {
        DisableDevtool({
          disableMenu: false,
          ignore: ['/login', /\/reset_password\/.+/]
        });
      }
    }
  }, [isUserAuth]);

  return (
    <>
      <BiometricScan />
      <Routes>
        <Route element={<CheckAuth AuthModal={<AuthModal />} />}>
          <Route path="/" element={<Layout />}>
            <Route path="/*" element={<NotFound />} />
            {!menu.find((item) => item.code === 'main') && (
              <Route path="/" element={<NotFound />} />
            )}

            <Route
              element={
                <ProtectedRoute
                  redirectPath={ROUTES.login}
                  isAllowed={isUserAuth}
                />
              }
            >
              <Route path={ROUTES.faq} element={<FAQ />} />
              <Route path={ROUTES.faqTemplate()} element={<FAQTemplate />} />

              {menu.map((item) => {
                const route =
                  ACCEPTED_ROUTES[item.code as keyof typeof ACCEPTED_ROUTES];
                return route ? route : null;
              })}

              <Route path={ROUTES.personal} element={<Personal />} />
              <Route path={ROUTES.settlement} element={<></>} />
              <Route path={ROUTES.litro} element={<Litro />} />
              <Route path={ROUTES.litroForm} element={<LitroForm />} />
              <Route path={ROUTES.editor} element={<></>} />

              {isAdmin && (
                <Route
                  path={`${ADMIN_ROUTES.root}/*`}
                  element={<AdminRoutes />}
                />
              )}
            </Route>
          </Route>
        </Route>

        <Route element={<SuspenseRoute />}>
          <Route path={ROUTES.login} element={<LoginPage />} />
        </Route>
        <Route element={<SuspenseRoute />}>
          <Route path={ROUTES.resetPassword()} element={<ResetPassword />} />
        </Route>
        <Route element={<SuspenseRoute />}>
          <Route path={ROUTES.approvements} element={<Approvement />} />
        </Route>
      </Routes>
    </>
  );
};
