import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  IAutoCompleteGroup,
  IAutoCompleteOptions
} from '@/components/Shared/ui/autocomplete';
import { setCustomOption } from '@/services';
import { useAppSelector } from '@/store';

export type KeyToUse = 'uuid' | 'code';

function generateYears(): IAutoCompleteOptions[] {
  const currentYear = new Date().getFullYear();
  const years: IAutoCompleteOptions[] = [];

  for (let i = 0; i <= 50; i++) {
    const year = (currentYear - i).toString();
    years.push({ value: year, label: year });
  }

  return years;
}

export interface CarInfoInterface {
  mark: string;
  model: string;
  year: string;
  dealer?: string;
}

interface IProps {
  keyToUse: KeyToUse;
  defaultValues?: CarInfoInterface;
}

const useCarInfo = ({ keyToUse, defaultValues }: IProps) => {
  const { dicts } = useAppSelector((state) => state.dict);
  const { dealer: chosenDealer } = useAppSelector((state) => state.userInfo);

  const [possibleMarks, setPossibleMarks] = useState<OptionsInterface[]>([]);
  const [possibleModels, setPossibleModels] = useState<
    Array<
      OptionsInterface & {
        isCommercial: boolean;
      }
    >
  >([]);
  const [possibleDealers, setPossibleDealers] = useState<OptionsInterface[]>(
    []
  );

  const models = useMemo(() => {
    return possibleModels.reduce((acc, item) => {
      if (!item.isCommercial) {
        acc[0] ??= { groupTitle: '', options: [] };
        acc[0].options.push(item);
        return acc;
      }

      acc[1] ??= { groupTitle: 'Коммерческие', options: [] };
      acc[1].options.push(item);
      return acc;
    }, [] as IAutoCompleteGroup[]);
  }, [possibleModels]);
  const years = useMemo(generateYears, []);

  const handleMarkChanged = useCallback(
    (findValue: string) => {
      const mark = dicts?.Brands.content.find(
        (item) => item[keyToUse].toString() === findValue
      );
      const possibleModelsHandler =
        dicts?.Models.content.filter((model) => {
          return model?.brand_uuid === mark?.uuid;
        }) || [];

      const optionsHandler = possibleModelsHandler
        .map((data) => {
          return {
            value: keyToUse === 'uuid' ? data.uuid : data.code.toString(),
            label: data.name,
            isCommercial: !!data.is_commercial
          };
        })
        .sort((a, b) => {
          if (a.isCommercial !== b.isCommercial) {
            return a.isCommercial ? 1 : -1;
          }
          return a.label.localeCompare(b.label);
        });
      setPossibleModels(optionsHandler);

      const dealerConnections = dicts?.Dealers_Used_Dealers.content.filter(
        (connection) => connection.dealer_uuid === chosenDealer?.value
      );
      const possibleDealersHandler =
        dicts?.Dealers.content
          .filter((dealer) => {
            return dealer?.sold_brands === mark?.uuid && dealer.is_active;
          })
          .filter((dealer) =>
            dealerConnections?.some(
              (item) => item.used_dealer_uuid === dealer.uuid
            )
          ) || [];
      const dealersOptionsHandler = setCustomOption(
        possibleDealersHandler,
        keyToUse === 'uuid'
      );
      setPossibleDealers(dealersOptionsHandler);
    },
    [dicts]
  );

  useEffect(() => {
    if (chosenDealer && dicts) {
      const dealerArray = dicts?.Dealers;
      const dealer = dealerArray.content.find(
        (content) =>
          content.code === parseInt(chosenDealer.code as unknown as string)
      );
      const possibleMarksHandler = [...(dicts?.Brands.content || [])].filter(
        (brand) => {
          if (dealer?.name.includes('Hongqi')) {
            return (
              brand.uuid === dealer?.sold_brands ||
              brand.name.toLowerCase() === 'faw'
            );
          } else {
            if (dealer?.sold_brands) {
              return brand.uuid === dealer?.sold_brands;
            } else {
              return brand;
            }
          }
        }
      );

      const optionsHandler = setCustomOption(
        possibleMarksHandler,
        keyToUse === 'uuid'
      );
      setPossibleMarks(optionsHandler);
    }
  }, [chosenDealer, dicts]);

  useEffect(() => {
    const mark = possibleMarks.find(
      (item) => item.label === defaultValues?.mark
    );
    if (mark) handleMarkChanged(mark.value);
  }, [defaultValues?.mark, possibleMarks]);

  return {
    models,
    possibleModels,
    possibleMarks,
    possibleDealers,
    years,
    handleMarkChanged
  };
};

export default useCarInfo;

interface OptionsInterface {
  value: string;
  label: string;
}
