import { Select, TablePaginationConfig } from 'antd';
import React, { ReactNode } from 'react';

const { Option: AntOption } = Select;

type SelectComponentProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  options: Array<{ label: string; value: number }>;
};

type OptionProps = {
  value: string | number;
  children: string | ReactNode;
};

const selectComponentClass: (
  pagination: TablePaginationConfig
) => React.FC<SelectComponentProps> & {
  Option: React.FC<OptionProps>;
} = (pagination) => {
  function InnerSelect({ value, onChange, options }: SelectComponentProps) {
    const checkedPagination =
      (pagination && typeof pagination !== 'boolean' && pagination) ||
      undefined;
    const currentAmount = (checkedPagination?.current || 0) * +value;
    const minAmount = currentAmount - +value + 1;
    const maxAmount =
      currentAmount > (checkedPagination?.total || 0)
        ? checkedPagination?.total
        : currentAmount;

    return (
      <>
        <span className="table__shown-text">
          {minAmount} - {maxAmount} из {pagination?.total}
        </span>

        <Select
          size="small"
          value={value}
          onChange={onChange}
          options={options.map((item) => ({
            label: `Показать ${item.value}`,
            value: item.value
          }))}
        />
      </>
    );
  }
  InnerSelect.Option = AntOption;

  return InnerSelect;
};

export default selectComponentClass;
