import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiConnector } from '@/integrations/api.connector';
import { leasingItemMapper } from '@/services/mappers/leasingItemMapper';
import { leasingsResponseMapper } from '@/services/mappers/leasingsResponseMapper';
import {
  LeasingParamsDto,
  TLeasingCreationDto,
  TLeasingEditDto
} from '@/types/leasing';

import { TLeasingsStore } from '../types/store';

const initialState: TLeasingsStore = {
  isLoading: false,
  leasings: [],
  totalLeasings: 0,
  targetLeasingUuid: '',
  selectedLeasing: null,
  leasingFiles: []
};

export const createLeasingDraft = createAsyncThunk(
  'leasings/createLeasingDraft',
  async () => {
    try {
      return await apiConnector.createLeasingDraft();
    } catch (e: any) {
      throw new Error(e.message);
    }
  }
);

export const updateLeasing = createAsyncThunk(
  'leasings/updateLeasing',
  async ({
    leasingUuid,
    leasingDto
  }: {
    leasingUuid: string;
    leasingDto: TLeasingCreationDto;
  }) => {
    try {
      return await apiConnector.updateLeasing(leasingUuid, leasingDto);
    } catch (e: any) {
      throw new Error(e.message);
    }
  }
);

export const submitLeasingOfferForReview = createAsyncThunk(
  'leasings/submitLeasingOfferForReview',
  async ({ offerUuid }: { offerUuid: string }) => {
    try {
      return await apiConnector.submitLeasingOfferForReview(offerUuid);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const approveLeasingOffer = createAsyncThunk(
  'leasings/approveLeasingOffer',
  async ({ offerUuid }: { offerUuid: string }) => {
    try {
      return await apiConnector.approveLeasingOffer(offerUuid);
    } catch (e: any) {
      throw new Error(e.error.message.includes('400') ? '400' : e.message);
    }
  }
);

export const declineLeasingOffer = createAsyncThunk(
  'leasings/declineLeasingOffer',
  async ({ offerUuid }: { offerUuid: string }) => {
    try {
      return await apiConnector.declineLeasingOffer(offerUuid);
    } catch (e: any) {
      throw new Error(e.error.message.includes('400') ? '400' : e.message);
    }
  }
);

export const editLeasingOffer = createAsyncThunk(
  'leasings/editLeasingOffer',
  async ({
    offerUuid,
    editLeasingDto
  }: {
    offerUuid: string;
    editLeasingDto: TLeasingEditDto;
  }) => {
    try {
      return await apiConnector.editLeasingOffer(offerUuid, editLeasingDto);
    } catch (e: any) {
      throw new Error(e.message);
    }
  }
);

export const getLeasingById = createAsyncThunk(
  'leasings/getLeasingById',
  async (leasingId: string) => {
    try {
      return await apiConnector.getLeasingById(leasingId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getLeasingFiles = createAsyncThunk(
  'leasings/getLeasingFiles',
  async (leasingId: string) => {
    try {
      return await apiConnector.getLeasingFiles(leasingId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getAllLeasings = createAsyncThunk(
  'leasings/getAllLeasings',
  async (params: LeasingParamsDto) => {
    try {
      return await apiConnector.getAllLeasings(params);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const leasingUpdateStatuses = createAsyncThunk(
  '/leasings/responses/forte/status',
  async (responseId: string) => {
    try {
      return await apiConnector.getLeasingUpdateStatus(responseId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const leasingsSlice = createSlice({
  name: 'leasings',
  initialState,
  reducers: {
    setSelectedLeasingToInitial: (state) => {
      state.selectedLeasing = null;
    },
    clearTargetLeasingUuid: (state) => {
      state.targetLeasingUuid = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLeasingDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createLeasingDraft.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createLeasingDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        state.targetLeasingUuid = action.payload.uuid;
      })

      .addCase(updateLeasing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLeasing.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateLeasing.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(getAllLeasings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLeasings.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllLeasings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leasings = leasingsResponseMapper(action.payload.data);
        state.totalLeasings = action.payload.total || 0;
      })

      .addCase(getLeasingById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeasingById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLeasingById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.targetLeasingUuid = action.payload.uuid;
        state.selectedLeasing = leasingItemMapper(action.payload);
      })
      .addCase(getLeasingFiles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeasingFiles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLeasingFiles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leasingFiles = action.payload;
      });
  }
});

export const { setSelectedLeasingToInitial, clearTargetLeasingUuid } =
  leasingsSlice.actions;
