import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { faqApi } from '@/components/App/faq/api/faq-api';
import { TPromotion } from '@/components/App/faq/types/promotions';
import { WIDTH_FOR_OPENED_NAVBAR } from '@/constants';
import { userInfoMapper } from '@/services/mappers/userInfoMapper';

import { apiConnector } from '../integrations/api.connector';
import { TUserInfoStore } from '../types/store';
import { DecodedJWTToken, TModule, TUserResponse } from '../types/user';

export const getUserAccount = createAsyncThunk(
  'userInfo/getUserAccount',
  async (): Promise<any> => {
    try {
      return await apiConnector.getUserAccount();
    } catch (e) {
      console.error(e);
    }
  }
);

export const getUserMenu = createAsyncThunk(
  'userInfo/getUserMenu',
  async (dealerId: string): Promise<any> => {
    try {
      return await apiConnector.getUserMenu(dealerId);
    } catch (e) {
      console.error(e);
    }
  }
);

export const getFiltersPromotions = createAsyncThunk(
  'promos/getFiltersPromotions',
  async (data: TPromotion) => faqApi.getFiltersPromotions(data)
);

const initialState: TUserInfoStore = {
  jwtDecodedInfo: null,
  userInfo: null,
  dealer: null,
  isLoading: false,
  menu: [],
  isSidebarExpanded: window.innerWidth > WIDTH_FOR_OPENED_NAVBAR,
  mobileMenuItem: null,
  adminMobileMenu: '/adminPage/products',
  promotions: []
};

export const userInfoSlice = createSlice({
  name: 'userInfoSlice',
  initialState,
  reducers: {
    setDealer: (
      state,
      action: PayloadAction<{ value: string; label: string; code: number }>
    ) => {
      state.dealer = action.payload;
    },
    setJwtDecodedInfo: (state, action: PayloadAction<DecodedJWTToken>) => {
      state.jwtDecodedInfo = action.payload;
    },
    setSidebarExpanded: (state, action: PayloadAction<boolean>) => {
      state.isSidebarExpanded = action.payload;
    },
    setMobileMenuItem: (state, action: PayloadAction<TModule>) => {
      state.mobileMenuItem = action.payload;
    },

    setAdminMobileMenu: (state, action) => {
      state.adminMobileMenu = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        getUserAccount.fulfilled,
        (state, action: PayloadAction<TUserResponse>) => {
          state.userInfo = userInfoMapper(action.payload);
        }
      )
      .addCase(
        getUserMenu.fulfilled,
        (state, action: PayloadAction<TModule[]>) => {
          state.menu = action.payload
            ? action.payload.sort((a, b) => a.position - b.position)
            : [];
          const filteredMobileItems = state.menu.filter((item) =>
            ['pos-credit', 'leasing', 'clients', 'reports'].includes(item.code)
          );
          state.mobileMenuItem = filteredMobileItems[0];
        }
      )
      .addCase(getFiltersPromotions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.promotions = action.payload;
      })
      .addCase(getFiltersPromotions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFiltersPromotions.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {
  setDealer,
  setJwtDecodedInfo,
  setSidebarExpanded,
  setMobileMenuItem,
  setAdminMobileMenu
} = userInfoSlice.actions;
