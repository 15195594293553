import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { formatPhoneNumberWithSpace } from '@/services/formatter';
import { useAppDispatch } from '@/store';
import { getClientByIin } from '@/store/clients.slice';

import { ClientFormValues } from '../types/client-form';

interface IProps {
  submitRequest: (values: ClientFormValues) => Promise<boolean>;
  handleNext: () => void;
}

const useClientForm = ({ submitRequest, handleNext }: IProps) => {
  const dispatch = useAppDispatch();

  const methods = useForm<ClientFormValues, unknown>({
    mode: 'all',
    delayError: 200
  });
  const values = methods.watch();

  const [searchClientLoading, setSearchClientLoading] = useState(false);
  const [isClientFind, setIsClientFind] = useState({
    fullName: false,
    phone: false
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [clientError, setClientError] = useState(false);

  useEffect(() => {
    // Сбрасываем ошибку при изменении полей
    if (clientError) {
      setClientError(false);
    }
  }, [values]);

  useEffect(() => {
    (async () => {
      if (values.iin?.length !== 12) return;

      setSearchClientLoading(true);
      const response = await dispatch(getClientByIin(values.iin));
      if (response.payload?.client_iin) {
        const data = response.payload;

        const newValues: ClientFormValues = {
          iin: response.payload?.client_iin,
          fullName: '',
          phone: '',
          sentWithType: 'SMS'
        };
        const shouldDisable = { fullName: false, phone: false };

        // Запрещаем редактирование полей, если соответствующие данные найдены
        if (data.last_name && data.first_name) {
          newValues.fullName = `${data.last_name} ${data.first_name} ${
            data.middle_name || ''
          }`;
          shouldDisable.fullName = newValues.fullName.length !== 0;
        }
        if (data.phone_number) {
          newValues.phone = formatPhoneNumberWithSpace(data.phone_number);
          const maskLength = '+7 (___) ___-__-__'.length;
          shouldDisable.phone = newValues.phone.length === maskLength;
        }
        methods.reset(newValues);

        setIsClientFind(shouldDisable);
      }
      setSearchClientLoading(false);
    })();
  }, [values.iin, dispatch]);

  useEffect(() => {
    // Разрешаем редактирование полей, если изменился ИИН
    if (isClientFind.fullName || isClientFind.phone) {
      setIsClientFind({ fullName: false, phone: false });
    }
  }, [values.iin]);

  const handleClientFormSubmit = async (values: ClientFormValues) => {
    setSubmitLoading(true);
    const response = await submitRequest(values);
    if (response) {
      handleNext();
    } else {
      setClientError(true);
    }
    setSubmitLoading(false);
  };

  return {
    clientFormMethods: methods,
    searchClientLoading,
    isClientFind,
    clientFormLoading: submitLoading,
    clientError,
    handleClientFormSubmit
  };
};

export default useClientForm;
