import './navbar.scss';

import { Tooltip } from 'antd';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { AdminNavBar } from '@/components/AdminPanel/NavBar/AdminNavBar';
import { ADMIN_ROUTES, ROUTES } from '@/components/Shared/routes/routes';
import {
  WIDTH_FOR_OPENED_NAVBAR,
  pathExpandLogo,
  pathLogo,
  pathQuestionicon
} from '@/constants';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTargetedClientToInitial } from '@/store/clients.slice';
import { onSetSelectedBankToInitial } from '@/store/financingStep.slice';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';
import { setSidebarExpanded } from '@/store/userInfo.slice';

const badgeMaxCount = 9999;

const Navbar = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { newLeadsCount } = useAppSelector((state) => state.leads);
  const { menu, isSidebarExpanded } = useAppSelector((state) => state.userInfo);

  const [windowWidth] = useWindowSize();

  const onClick = () => {
    dispatch(onSetSelectedBankToInitial());
    dispatch(setTargetedClientToInitial());
    dispatch(resetReqSuccessIndicator());
    if (windowWidth < WIDTH_FOR_OPENED_NAVBAR)
      dispatch(setSidebarExpanded(false));
  };

  useEffect(() => {
    if (windowWidth !== 0)
      dispatch(setSidebarExpanded(windowWidth > WIDTH_FOR_OPENED_NAVBAR));
  }, [windowWidth]);

  return (
    <>
      <div
        onClick={() => dispatch(setSidebarExpanded(false))}
        className={`navbar-overlay ${isSidebarExpanded ? 'navbar-overlay--shown' : ''}`}
      ></div>
      <aside
        className={`navbar ${
          isSidebarExpanded ? 'navbar--expanded' : 'navbar--collapsed'
        }`}
      >
        <div className="navbar__logo-box">
          <NavLink
            className="navbar__logo"
            to={ROUTES.autoCredit}
            onClick={onClick}
          >
            <img src={pathLogo} alt="logo" />
          </NavLink>
          <button
            className="navbar__arrow"
            onClick={() => dispatch(setSidebarExpanded(!isSidebarExpanded))}
          >
            <img src={pathExpandLogo} alt="logo_expand" />
          </button>
        </div>

        <nav className="navbar__navigation-box">
          {location.pathname.includes(ADMIN_ROUTES.root) ? (
            <AdminNavBar isSidebarExpanded={isSidebarExpanded} />
          ) : (
            menu.map((element, index) => {
              const isMainPage = element.code === 'main';
              const isActive =
                (isMainPage && location.pathname === ROUTES.home) ||
                location.pathname.includes(element.code);
              const href = isMainPage ? ROUTES.home : `/${element.code}`;

              const linkClasses = [
                'navbar__link',
                isMainPage ? 'navbar__link--main-page' : '',
                !isMainPage && index === 0 ? 'navbar__link--margin-top' : '',
                isActive ? 'navbar__link--active' : ''
              ].join(' ');

              return (
                <NavLink
                  to={href}
                  className={linkClasses}
                  onClick={onClick}
                  key={element.uuid}
                >
                  <Tooltip
                    open={isSidebarExpanded ? false : undefined}
                    title={element?.name}
                    placement="right"
                  >
                    <img
                      className="navbar__link-icon"
                      src={element.icon}
                      alt="setting_icon"
                    />
                  </Tooltip>
                  <span className="navbar__link-text">{element?.name}</span>
                  {element.code === 'leads' && newLeadsCount > 0 && (
                    <span
                      className={`navbar__link-badge ${isSidebarExpanded ? '' : 'navbar__link-badge--collapsed'}`}
                    >
                      {Math.min(newLeadsCount, badgeMaxCount) +
                        (newLeadsCount > badgeMaxCount ? '+' : '')}
                    </span>
                  )}
                </NavLink>
              );
            })
          )}
        </nav>

        {!location.pathname.includes(ADMIN_ROUTES.root) && (
          <div className="navbar__support-setting-box">
            <NavLink
              to={ROUTES.faq}
              onClick={onClick}
              className={`navbar__link ${location.pathname.includes(ROUTES.faq) ? 'navbar__link--active' : ''}`}
            >
              <img
                className="navbar__link-icon"
                src={pathQuestionicon}
                alt="support_icon"
              />
              <p className="navbar__link-text">Поддержка</p>
            </NavLink>
          </div>
        )}
      </aside>
    </>
  );
};

export default Navbar;
