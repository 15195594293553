import './otp-form.scss';

import { Controller, UseFormReturn } from 'react-hook-form';

import { OtpFormValues } from '../../types/otp-form';

interface IProps {
  resetOtpError: () => void;
  methods: UseFormReturn<OtpFormValues, unknown, undefined>;
  onSubmit: (values: OtpFormValues) => void;
}

const OtpForm: React.FC<IProps> = ({ resetOtpError, methods, onSubmit }) => {
  const {
    control,
    formState: { errors },
    watch,
    trigger
  } = methods;
  const values = watch(['first', 'second', 'third', 'fourth']);

  const handleChange = (value: string, onChange: (value: string) => void) => {
    resetOtpError();
    if (value.match(/\D/) || value.length > 1) return false;
    onChange(value);
    const isValid = values.filter(Boolean).length >= 3;
    if (isValid) methods.handleSubmit(onSubmit)();
    return true;
  };

  return (
    <div className="otp-form">
      <Controller
        control={control}
        name="first"
        rules={{ required: true, minLength: 1 }}
        render={({ field }) => (
          <input
            {...field}
            type="tel"
            value={field.value ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              const shouldContinue = handleChange(value, field.onChange);
              if (value.length === 1 && shouldContinue)
                methods.setFocus('second');
            }}
            className={`otp-form__field ${
              errors.first ? 'otp-form__field--error' : ''
            }`}
            autoComplete="off"
          />
        )}
      />
      <Controller
        control={methods.control}
        name="second"
        rules={{ required: true, minLength: 1 }}
        render={({ field }) => (
          <input
            {...field}
            type="tel"
            value={field.value ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              const shouldContinue = handleChange(value, field.onChange);
              if (value.length === 1 && shouldContinue)
                methods.setFocus('third');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && !field.value)
                methods.setFocus('first');
            }}
            className={`otp-form__field ${
              errors.second ? 'otp-form__field--error' : ''
            }`}
            autoComplete="off"
          />
        )}
      />
      <Controller
        control={methods.control}
        name="third"
        rules={{ required: true, minLength: 1 }}
        render={({ field }) => (
          <input
            {...field}
            type="tel"
            value={field.value ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              const shouldContinue = handleChange(value, field.onChange);
              if (value.length === 1 && shouldContinue)
                methods.setFocus('fourth');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && !field.value)
                methods.setFocus('second');
            }}
            className={`otp-form__field ${
              errors.third ? 'otp-form__field--error' : ''
            }`}
            autoComplete="off"
          />
        )}
      />
      <Controller
        control={methods.control}
        name="fourth"
        rules={{ required: true, minLength: 1 }}
        render={({ field }) => (
          <input
            {...field}
            type="tel"
            value={field.value ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              const shouldContinue = handleChange(value, field.onChange);
              if (value.length === 1 && shouldContinue)
                trigger(['first', 'second', 'third', 'fourth'], {
                  shouldFocus: true
                });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && !field.value)
                methods.setFocus('third');
            }}
            className={`otp-form__field ${
              errors.fourth ? 'otp-form__field--error' : ''
            }`}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
};

export default OtpForm;
