import { TFlashLog } from '@/types/flashLog.type';

import { ApiConnector } from '../api.connector';

export class LogsApi extends ApiConnector {
  public async getAdminLogs(
    param: string,
    limit: number,
    page: number
  ): Promise<{ total: number; flash_logs: TFlashLog[] }> {
    return this.call({
      path: `admin/flashlogs/getFlashLogs/${param}`,
      params: {
        limit,
        page
      }
    });
  }
}

export const logsApi = new LogsApi();
