import { ApiConnector } from '@/integrations/api.connector';

import { FetchedFAQListInterface } from '../types/faq-api';
import { TPromotion } from '../types/promotions';

class FAQApi extends ApiConnector {
  private baseUrl = 'faq';

  public async getFAQByModule(moduleId: string) {
    return this.call<unknown, FetchedFAQListInterface[]>({
      path: `${this.baseUrl}/module/${moduleId}`
    });
  }

  public async getFAQInfo(faqId: string) {
    return this.call<unknown, FetchedFAQListInterface>({
      path: `${this.baseUrl}/${faqId}`
    });
  }

  public async getFiltersPromotions(data: TPromotion) {
    const path = 'faq/promos';
    return await this.call({ path, method: 'post', body: data });
  }
}

export const faqApi = new FAQApi();
