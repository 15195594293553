import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';

export interface HistoryDataSourceInterface {
  comment: string;
  date: string;
  initiator: string;
}

export const historyTableDataSource: ColumnsType<HistoryDataSourceInterface> = [
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    width: 300,
    render: (text, record) => (
      <span className="order-history__cell-text">{record.comment}</span>
    )
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    render: (text, record) => (
      <span className="order-history__cell-text">
        {dayjs(record.date).format('DD.MM.YYYY, HH:mm:ss')}
      </span>
    )
  },
  {
    title: 'Инициатор',
    dataIndex: 'initiator',
    key: 'initiator',
    sorter: (a, b) => a.initiator.localeCompare(b.initiator),
    render: (text, record) => (
      <span className="order-history__cell-text">{record.initiator}</span>
    )
  }
];
