import { useEffect, useState } from 'react';

import { apiConnector } from '@/integrations/api.connector';
import { useAppSelector } from '@/store';

import { HistoryDataSourceInterface } from '../table-data-source';
import { AutoCreditHistoryResponseInterface } from '../types/auto-credit-history';

interface IProps {
  shouldRequest: boolean;
}

const useAutoCreditHistory = ({ shouldRequest }: IProps) => {
  const { requestId } = useAppSelector((state) => state.orderStep);

  const [autoCreditData, setAutoCreditData] = useState<
    HistoryDataSourceInterface[] | undefined
  >(undefined);
  const [autoCreditLoading, setAutoCreditLoading] = useState(false);

  useEffect(() => {
    if (!shouldRequest || !requestId) return;

    (async () => {
      setAutoCreditLoading(true);
      try {
        const response =
          await apiConnector.getOrderHistory<
            AutoCreditHistoryResponseInterface[]
          >(requestId);
        if (!response?.[0]?.result) return;

        setAutoCreditData(
          response[0].result.map((item) => ({
            date: item.create_date,
            comment: item.data.description,
            initiator: item.data.initiator
          }))
        );
      } finally {
        setAutoCreditLoading(false);
      }
    })();
  }, [shouldRequest, requestId]);

  return {
    autoCreditData,
    totalAutoCreditData: autoCreditData?.length,
    autoCreditLoading
  };
};

export default useAutoCreditHistory;
