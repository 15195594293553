import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { clientResponseMapper } from '@/services/mappers/clientResponseMapper';

import { apiConnector } from '../integrations/api.connector';
import { TClientServerResponse } from '../types/clients';
import { TClientStoreFormat, TClientsStore } from '../types/store';

export const getClientById = createAsyncThunk(
  'clients/getClientById',
  async (id: string): Promise<any> => {
    try {
      return await apiConnector.getClientById(id);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getClientByIin = createAsyncThunk(
  'clients/getClientByIin',
  async (iin: string): Promise<any> => {
    try {
      return await apiConnector.getClientByIin(iin);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TClientsStore = {
  clients: [],
  targetedClient: null,
  isLoading: false
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setTargetedClient: (state, action: PayloadAction<TClientStoreFormat>) => {
      state.targetedClient = action.payload;
    },
    setTargetedClientToInitial: (state) => {
      state.targetedClient = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getClientById.fulfilled,
        (state, action: PayloadAction<TClientServerResponse>) => {
          state.isLoading = false;
          const actionPayloadArray = [action.payload];
          state.clients = clientResponseMapper(actionPayloadArray);
        }
      );
  }
});

export const { setTargetedClient, setTargetedClientToInitial } =
  clientsSlice.actions;
