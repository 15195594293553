import './auth-modal.scss';

import { Modal } from 'antd';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/components/Shared/routes/routes';
import { Button } from '@/components/Shared/ui';
import { pathLogoAllurRed } from '@/constants';
import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { useAppDispatch, useAppSelector } from '@/store';
import { login, setIsAuthErrorModal } from '@/store/auth.slice';

import { LoginFields } from '../../entities';
import { IFormInput } from '../../types/form-types';

type IModalFormInput = Omit<IFormInput, 'dealer'>;

const AuthModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { isAuthErrorModal } = useAppSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<IModalFormInput>({
    mode: 'all',
    delayError: 200,
    defaultValues: {
      email: userInfo?.email || '',
      password: '',
      isRemember: false
    }
  });
  const {
    formState: { errors },
    reset,
    handleSubmit
  } = methods;

  const onSubmit = async (data: IModalFormInput) => {
    setIsLoading(true);

    const loginResponse = await dispatch(login(data));
    if (loginResponse.payload?.status) {
      reset();
      dispatch(setIsAuthErrorModal(''));
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    dispatch(setIsAuthErrorModal(''));
    clearLocalStorageHelper();
    const savedUrl =
      window.location.pathname !== ROUTES.login &&
      !window.location.pathname.includes('create')
        ? `${window.location.pathname}${window.location.search}`
        : '';
    navigate(ROUTES.login, { state: { savedUrl } });
  };

  return (
    <Modal
      centered
      maskClosable={true}
      className="auth-modal"
      open={isAuthErrorModal !== ''}
      onClose={handleClose}
      onCancel={handleClose}
      footer={<></>}
    >
      {isAuthErrorModal === 'finally' && (
        <>
          <div className="auth-modal__header">
            <div className="auth-modal__logo">
              <img src={pathLogoAllurRed} alt="Allur Finance" width={80} />
            </div>
            <h2 className="auth-modal__title">Ошибка авторизации!</h2>
          </div>
          <p className="auth-modal__description">
            Ваша сессия истекла. Пожалуйста, войдите в систему.
          </p>

          <Button variant="filled-red" fullWidth onClick={handleClose}>
            Продолжить
          </Button>
        </>
      )}
      {isAuthErrorModal === 'reauthenticate' && (
        <FormProvider {...methods}>
          <form className="auth-modal__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="auth-modal__header">
              <div className="auth-modal__logo">
                <img src={pathLogoAllurRed} alt="Allur Finance" width={80} />
              </div>
              <h2 className="auth-modal__title">Вход в систему</h2>
            </div>
            <p className="auth-modal__description">
              Ваша сессия истекла. Пожалуйста, войдите в систему.
            </p>

            <LoginFields />

            <Button
              type="submit"
              className="auth-modal__submit"
              variant="filled-red"
              disabled={Object.values(errors).length !== 0}
              isLoading={isLoading}
              fullWidth
            >
              Войти
            </Button>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default AuthModal;
