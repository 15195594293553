import './admin-navbar.scss';

import {
  BankOutlined,
  CarOutlined,
  FundProjectionScreenOutlined,
  IdcardOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  ShopOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  ADMIN_ROUTES,
  ROUTES,
  getFullAdminRoute
} from '@/components/Shared/routes/routes';
import { WIDTH_FOR_OPENED_NAVBAR } from '@/constants';
import { useWindowSize } from '@/hooks/useWindowSize';
import IconMessage from '@/media/icons/message.svg?react';
import IconModules from '@/media/svg/Filter.svg?react';
import IconLeasing from '@/media/svg/percent.svg?react';
import { useAppDispatch } from '@/store';
import { setSidebarExpanded } from '@/store/userInfo.slice';

type TAdminNavBarProps = {
  isSidebarExpanded: boolean;
};

export type TAdminSideBarItem = {
  label: string;
  key: string;
  icon: JSX.Element;
};

export const adminSideBarItems = [
  {
    label: 'На главную',
    key: ROUTES.autoCredit,
    icon: <LogoutOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Дилерские центры',
    key: getFullAdminRoute(ADMIN_ROUTES.dealers),
    icon: <ShopOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Банки',
    key: getFullAdminRoute(ADMIN_ROUTES.banks),
    icon: <BankOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Клиенты',
    key: getFullAdminRoute(ADMIN_ROUTES.clients),
    icon: <IdcardOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Лизинг',
    key: getFullAdminRoute(ADMIN_ROUTES.leasings),
    icon: <IconLeasing className="admin-navbar__link-icon" />
  },
  {
    label: 'Продукты',
    key: getFullAdminRoute(ADMIN_ROUTES.products),
    icon: <ProjectOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Авто',
    key: getFullAdminRoute(ADMIN_ROUTES.auto),
    icon: <CarOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Пользователи',
    key: getFullAdminRoute(ADMIN_ROUTES.users),
    icon: <UserOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Модули',
    key: getFullAdminRoute(ADMIN_ROUTES.modules),
    icon: <IconModules className="admin-navbar__link-icon" />
  },
  {
    label: 'Логи',
    key: getFullAdminRoute(ADMIN_ROUTES.logs),
    icon: <ProfileOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Отчеты',
    key: getFullAdminRoute(ADMIN_ROUTES.reports),
    icon: <FundProjectionScreenOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Поддержка',
    key: getFullAdminRoute(ADMIN_ROUTES.faq),
    icon: <QuestionCircleOutlined className="admin-navbar__link-icon" />
  },
  {
    label: 'Системные уведомления',
    key: getFullAdminRoute(ADMIN_ROUTES.systemNotifications),
    icon: <IconMessage className="admin-navbar__link-icon" />
  }
];

export const AdminNavBar: FC<TAdminNavBarProps> = ({ isSidebarExpanded }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [windowWidth] = useWindowSize();

  const onClick = () => {
    if (windowWidth < WIDTH_FOR_OPENED_NAVBAR)
      dispatch(setSidebarExpanded(false));
  };

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={!isSidebarExpanded}
      className="admin-navbar"
      style={{
        marginLeft: isSidebarExpanded ? '-16px' : '0px',
        width: !isSidebarExpanded ? '50px' : '230px'
      }}
    >
      {Array.isArray(adminSideBarItems) &&
        adminSideBarItems.map((item) => {
          const isActive = location.pathname.includes(item.key);

          return (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              className={`admin-navbar__menu-item ${isActive ? 'admin-navbar__link--active' : ''}`}
            >
              <NavLink
                to={item.key}
                onClick={onClick}
                className={'admin-navbar__link'}
              >
                {item.label}
              </NavLink>
            </Menu.Item>
          );
        })}
    </Menu>
  );
};
