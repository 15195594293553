import {
  TBrand,
  TCreateBrand,
  TCreateEquipment,
  TEquipment,
  TModel,
  TModelCreate
} from '@/types/model.type';

import { ApiConnector } from '../api.connector';

export class ModelsApi extends ApiConnector {
  public getBrands(
    limit?: number,
    page?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TBrand[];
  }> {
    return this.call({
      path: 'admin/brands',
      params: {
        limit,
        page,
        searchTerm
      }
    });
  }

  public getModels(
    limit?: number,
    page?: number,
    searchTerm?: string,
    brand?: string,
    isCommercial?: boolean
  ): Promise<{
    total: number;
    data: TModel[];
  }> {
    return this.call({
      path: 'admin/models',
      params: {
        limit,
        page,
        searchTerm,
        brand,
        isCommercial
      }
    });
  }

  public getEquipments(
    limit?: number,
    page?: number,
    searchTerm?: string,
    brand?: string,
    model?: string
  ): Promise<{
    total: number;
    data: TEquipment[];
  }> {
    return this.call({
      path: 'admin/equipments',
      params: {
        limit,
        page,
        searchTerm,
        brand,
        model
      }
    });
  }

  public createModels(data: TModelCreate) {
    return this.call({ path: `admin/models`, method: 'post', body: data });
  }

  public createBrands(data: TCreateBrand) {
    return this.call({ path: `admin/brands`, method: 'post', body: data });
  }

  public createEquipments(data: TCreateEquipment) {
    return this.call({ path: `admin/equipments`, method: 'post', body: data });
  }

  public updateBrands(brandsId: string, data: TCreateBrand) {
    return this.call({
      path: `admin/brands/${brandsId}`,
      method: 'patch',
      body: data
    });
  }
  public getAdminBrandId(uuid: string): Promise<TBrand> {
    return this.call({ path: `admin/brands/${uuid}` });
  }

  public updateModels(modelsId: string, data: TModelCreate) {
    return this.call({
      path: `admin/models/${modelsId}`,
      method: 'patch',
      body: data
    });
  }
  public getAdminModelId(uuid: string): Promise<TModel> {
    return this.call({ path: `admin/models/${uuid}` });
  }

  public updateEquipments(equipmentId: string, data: TCreateEquipment) {
    return this.call({
      path: `admin/equipments/${equipmentId}`,
      method: 'patch',
      body: data
    });
  }
  public getAdminEquipmentId(uuid: string): Promise<TEquipment> {
    return this.call({ path: `admin/equipments/${uuid}` });
  }
}

export const modelsApi = new ModelsApi();
