import {
  TCreateLeasing,
  TLeasingCompanyResponse,
  TLeasingData
} from '@/types/leasing';

import { ApiConnector } from '../api.connector';

export class LeasingAdminApi extends ApiConnector {
  public getAdminAllLeasing(
    limit?: number,
    offset?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TLeasingData[];
  }> {
    return this.call({
      path: 'admin/leasing_companies',
      params: {
        limit,
        offset,
        searchTerm
      }
    });
  }

  public createLeasing(data: TCreateLeasing) {
    return this.call({
      path: 'admin/leasing_companies',
      method: 'post',
      body: data
    });
  }

  public updateAdminLeasing(leasingId: string, data: TCreateLeasing) {
    return this.call({
      path: `admin/leasing_companies/${leasingId}`,
      method: 'patch',
      body: data
    });
  }

  public getAdminLeasingById(uuid: string): Promise<TLeasingCompanyResponse> {
    return this.call({ path: `admin/leasing_companies/${uuid}` });
  }
}

export const leasingAdminApi = new LeasingAdminApi();
