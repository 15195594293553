import './client-otp-modal.scss';

import { Modal } from 'antd';
import { useEffect, useState } from 'react';

import AlertCircleIcon from '@/media/icons/alert-circle.svg?react';
import { formatPhoneNumberWithSpace } from '@/services/formatter';

import { Button } from '../../ui';
import useClientForm from './hooks/useClientForm';
import useOtpForm from './hooks/useOtpForm';
import { ClientFormValues } from './types/client-form';
import { ModalOpenerType } from './types/general';
import { OtpFormValues } from './types/otp-form';
import { ClientForm, Loader, OtpForm, Timer } from './ui';

interface IProps {
  title: string;
  subtitle?: string;
  onClientSubmit: (values: ClientFormValues) => Promise<boolean>;
  onOtpSubmit: (values: OtpFormValues) => Promise<string>;
  onWhatsappRetry?: (phone: string) => void;
  onSMSRetry: (phone: string) => void;
  defaultOpenedModal: ModalOpenerType;
  onClose: () => void;
  defaultPhone?: string;
}

const ClientOtpModal: React.FC<IProps> = ({
  title,
  subtitle,
  onClientSubmit,
  onOtpSubmit,
  defaultOpenedModal,
  onClose,
  onWhatsappRetry,
  onSMSRetry,
  defaultPhone
}) => {
  const [openedModal, setOpenedModal] = useState<ModalOpenerType>(0);

  const {
    clientFormMethods,
    searchClientLoading,
    isClientFind,
    clientFormLoading,
    clientError,
    handleClientFormSubmit
  } = useClientForm({
    submitRequest: onClientSubmit,
    handleNext: () => setOpenedModal(2)
  });
  const phone = clientFormMethods.watch('phone');

  const handleClose = () => {
    onClose();
    setOpenedModal(0);
    resetOtpError();
  };

  const {
    otpFormMethods,
    handleOtpFormSubmit,
    otpLoading,
    otpError,
    resetOtpError
  } = useOtpForm({
    submitRequest: onOtpSubmit,
    onClose: handleClose
  });

  useEffect(() => {
    if (defaultOpenedModal !== 0) {
      setOpenedModal(defaultOpenedModal);
    }
  }, [defaultOpenedModal]);

  useEffect(() => {
    if (openedModal === 0) {
      clientFormMethods.reset({
        iin: '',
        fullName: '',
        phone: ''
      });
    }
    if (openedModal === 0 || openedModal === 1) {
      otpFormMethods.reset({
        first: undefined,
        second: undefined,
        third: undefined,
        fourth: undefined
      });
    }
  }, [openedModal]);

  useEffect(() => {
    if (defaultOpenedModal === 1) {
      clientFormMethods.clearErrors(['iin', 'fullName', 'phone']);
      // Фокусим после открытия модалки, иначе событие теряется
      setTimeout(() => clientFormMethods.setFocus('iin'), 200);
    } else if (defaultOpenedModal === 2) {
      otpFormMethods.clearErrors(['first', 'second', 'third', 'fourth']);
      // Фокусим после открытия модалки, иначе событие теряется
      setTimeout(() => otpFormMethods.setFocus('first'), 200);
    }
  }, [defaultOpenedModal]);

  return (
    <>
      <Modal
        centered={true}
        className="client-otp-modal"
        title={title}
        open={openedModal === 1}
        onClose={handleClose}
        onCancel={handleClose}
        maskClosable={true}
        footer={<></>}
      >
        {subtitle && <p className="client-otp-modal__subtitle">{subtitle}</p>}

        <ClientForm
          methods={clientFormMethods}
          searchClientLoading={searchClientLoading}
          isClientFind={isClientFind}
          handleClose={handleClose}
          clientFormLoading={clientFormLoading}
          clientError={clientError}
          handleClientFormSubmit={handleClientFormSubmit}
          hasWhatsApp={onWhatsappRetry !== undefined}
        />
      </Modal>

      <Modal
        centered={true}
        className="client-otp-modal"
        title="Введите код подтверждения"
        open={openedModal === 2}
        onClose={handleClose}
        destroyOnClose={true}
        onCancel={handleClose}
        maskClosable={true}
        footer={<></>}
      >
        <p className="client-otp-modal__subtitle">
          Код подтверждения отправлен на номер
        </p>
        <p className="client-otp-modal__phone">
          {formatPhoneNumberWithSpace(phone || defaultPhone || '')}
        </p>

        <div className="client-otp-modal__form">
          <OtpForm
            resetOtpError={resetOtpError}
            methods={otpFormMethods}
            onSubmit={handleOtpFormSubmit}
          />
        </div>
        {otpError && (
          <p className="client-otp-modal__error">
            <AlertCircleIcon />
            {otpError}
          </p>
        )}

        <div
          className={`client-otp-modal__otp-loading ${otpLoading ? 'client-otp-modal__otp-loading--active' : ''}`}
        >
          <Loader />
          <p>Подождите...</p>
        </div>
        <div
          className={`client-otp-modal__timer ${!otpLoading ? 'client-otp-modal__timer--active' : ''}`}
        >
          <Timer
            timerStarted={openedModal === 2}
            onRetry={(type) =>
              type === 'phone'
                ? onSMSRetry(phone || defaultPhone || '')
                : onWhatsappRetry?.(phone || defaultPhone || '')
            }
            hasWhatsApp={onWhatsappRetry !== undefined}
            isContinuing={!defaultPhone}
          />
        </div>

        {!otpLoading && (
          <Button onClick={handleClose} className="client-otp-modal__cancel">
            Отмена
          </Button>
        )}
      </Modal>
    </>
  );
};

export default ClientOtpModal;
