import {
  TUserDto,
  TUserResponse,
  TUserUpdateDto,
  UpdateUserPasswordInterface
} from '@/types/user.type';

import { ApiConnector } from '../api.connector';

export class UserApi extends ApiConnector {
  public getAdminAllUsers(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    isActive?: boolean,
    position?: string
  ): Promise<{
    total: number;
    data: TUserDto[];
  }> {
    return this.call({
      path: 'admin/users',
      params: {
        limit,
        offset,
        searchTerm,
        ...(isActive !== undefined ? { isActive } : {}),
        position
      }
    });
  }

  public createAdminUser(data: TUserDto) {
    const newData: UserUpdatePayloadInterface = {
      birthDate: new Date(data.birth_date).toISOString(),
      email: data.email || '',
      firstName: data.first_name || '',
      iin: data.user_iin || '',
      lastName: data.last_name,
      login: data.login,
      middleName: data.middle_name,
      password: data.password,
      phoneNumber: data.phone_number,
      positionId: data.position?.uuid || '',
      salePointId: data.sale_point?.uuid,
      workDateStart: new Date(data.work_date_start).toISOString(),
      dealerIds: data.dealerIds,
      activity_type: data.activity_type,
      freezing_start_date: data.freezing_start_date,
      freezing_end_date: data.freezing_end_date || null
    };
    return this.call({
      path: 'admin/users',
      method: 'post',
      body: newData
    });
  }

  public updateAdminUser(
    userId: string,
    data: TUserUpdateDto | Omit<UpdateUserPasswordInterface, 'uuid'>
  ) {
    return this.call({
      path: `admin/users/${userId}`,
      method: 'patch',
      body: data
    });
  }

  public getAdminUserById(uuid: string): Promise<TUserResponse> {
    return this.call({
      path: `admin/users/${uuid}`
    });
  }
}

interface UserUpdatePayloadInterface {
  positionId: string;
  salePointId: string | undefined;
  iin: string;
  login: string;
  password: string | undefined;
  workDateStart: string | Date;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: string | Date;
  phoneNumber: string;
  email: string;
  dealerIds: string[];
  activity_type: string;
  freezing_start_date?: string;
  freezing_end_date?: string | null;
}
export const userApi = new UserApi();
