import AlertCircle from '@/media/icons/alert-circle.svg?react';
import CloseCircle from '@/media/icons/close-circle.svg?react';
import InfoCircle from '@/media/icons/info-circle.svg?react';
import Like from '@/media/icons/like.svg?react';

export const NOTIFICATION_TYPES = {
  ERROR: {
    icon: <CloseCircle />,
    textColor: '#FF2E1F',
    borderColor: '#FF2E1F',
    backgroundColor: '#FFF5F5'
  },
  WARNING: {
    icon: <AlertCircle />,
    textColor: '#111827',
    borderColor: '#E6BB20',
    backgroundColor: '#FFF7D9'
  },
  INFO: {
    icon: <InfoCircle />,
    textColor: '#1F9AFF',
    borderColor: '#1F9AFF',
    backgroundColor: '#F5F9FF'
  },
  ALERT: {
    icon: <Like />,
    textColor: '#0BA259',
    borderColor: '#0CAF60',
    backgroundColor: '#EAFFF1'
  }
};
