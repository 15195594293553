import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientOtpModal } from '@/components/Shared/entities';
import { ROUTES } from '@/components/Shared/routes/routes';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  setShowVerifyFirstStep,
  setShowVerifySecondStep
} from '@/store/app.slice';
import { createLitro, getLitro, validateOtpLitro } from '@/store/litro.slice';
import { TClientInfoOtpDto } from '@/types/otp.type';

export const LitroHeader: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { dealer } = useAppSelector((state) => state.userInfo);
  const { litroUuid } = useAppSelector((state) => state.litro);

  const [isValidateModalOpened, setIsValidateModalOpened] = useState<0 | 1 | 2>(
    0
  );

  const sendClientHandler = async (client: TClientInfoOtpDto) => {
    const response = await dispatch(createLitro(client));

    if ('error' in response && response.error) return false;
    dispatch(getLitro({}));
    dispatch(setShowVerifySecondStep(true));
    dispatch(setShowVerifyFirstStep(false));
    return true;
  };

  const sendOtpHandler = async (value: string) => {
    const response = await dispatch(
      validateOtpLitro({ uuid: litroUuid!, otp: value })
    );

    if ('error' in response && response.error)
      return 'Неверный код подтверждения! Попробуйте ещё раз';
    dispatch(setShowVerifySecondStep(false));
    navigate(ROUTES.litroForm);
    return '';
  };

  return (
    <>
      <div className="litro__header">
        <div className="title">
          <h2>Дополнительная услуга LITRO (ПНД)</h2>
          <p>Здесь небольшое описание</p>
        </div>
        <div className="pagination">
          <button
            onClick={() => {
              setIsValidateModalOpened(1);
            }}
          >
            <p>Новая заявка</p>
          </button>
        </div>
      </div>

      <ClientOtpModal
        title={'Создание заявки'}
        subtitle="Заполните данные о клиенте"
        onClientSubmit={(values) => {
          const splittedFullName = values.fullName
            .replace(/\s{2,}/g, ' ')
            .split(' ');
          return sendClientHandler({
            clientPhone: values.phone,
            iin: values.iin,
            dealer_uuid: dealer?.value || '',
            lastName: splittedFullName[0] || ' ',
            firstName: splittedFullName[1] || ' ',
            middleName: splittedFullName[2] || ' '
          });
        }}
        onOtpSubmit={(values) => {
          const code =
            values.first + values.second + values.third + values.fourth;
          return sendOtpHandler(code);
        }}
        onSMSRetry={() => null}
        defaultOpenedModal={isValidateModalOpened}
        onClose={() => setIsValidateModalOpened(0)}
      />
    </>
  );
};
