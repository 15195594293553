import './loader.scss';

const Loader: React.FC = () => {
  return (
    <div className="loader bar">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
