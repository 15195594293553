export const ROUTES = {
  login: '/login',
  resetPassword: (token = ':token') => `/reset_password/${token}`,

  home: '/',
  faq: '/faq',
  faqTemplate: (uuid = ':uuid') => `/faq/${uuid}`,

  autoCredit: '/auto-credit',
  autoCreditCreate: '/auto-credit/create',
  autoCreditRequest: (uuid = ':uuid') => `/auto-credit/${uuid}`,
  calculator: '/calculator',

  insurances: '/insurances',
  insurancesCreate: '/insurances/create',
  insurancesRequest: (uuid = ':uuid') => `/insurances/${uuid}`,

  personal: '/personal',
  settlement: '/settlement',
  reports: '/reports',
  editor: '/editor',

  leasing: '/leasing',
  leasingCreate: '/leasing/create',
  leasingRequest: (uuid = ':uuid') => `/leasing/${uuid}`,

  posCredit: '/pos-credit',
  posCreditCreate: (id = ':id') => `/pos-credit/create/${id}`,
  posCreditRequest: (id = ':id') => `/pos-credit/${id}`,

  clients: '/clients',
  client: (uuid = ':uuid') => `/clients/${uuid}`,

  litro: '/additional-product-pnd',
  litroForm: '/pnd-form',
  approvements: '/approvements',
  leads: '/leads',
  leadsUpdate: (uuid = ':uuid') => `/leads/${uuid}`
};

export const ADMIN_ROUTES = {
  root: '/adminPage',
  home: '/',

  salePoints: '/sale-points',
  banks: '/banks',
  banksCreate: '/banks/create',
  bank: (id = ':id') => `/banks/${id}`,
  executor: '/executor',
  ide: '/ide',
  logs: '/logs',
  reports: '/reports',

  faq: '/faq',
  faqCreate: '/faq/create',
  faqInfo: (id = ':id') => `/faq/${id}`,

  auto: '/auto',
  brandsCreate: '/auto/brands/create',
  brand: (id = ':id') => `/auto/brands/${id}`,
  modelsCreate: '/auto/models/create',
  model: (id = ':id') => `/auto/models/${id}`,
  equipmentsCreate: '/auto/equipments/create',
  equipment: (id = ':id') => `/auto/equipments/${id}`,

  products: '/products',
  productsCreate: '/products/create',
  product: (id = ':id') => `/products/${id}`,

  clients: '/clients',
  client: (id = ':id') => `/clients/${id}`,

  users: '/users',
  usersCreate: '/users/create',
  user: (id = ':id') => `/users/${id}/update`,
  positionCreate: '/users/position/create',
  position: (id = ':id') => `/users/position/${id}/update`,
  rolesActionCreate: '/users/roles_action/create',
  rolesAction: (id = ':id') => `/users/roles_action/${id}/update`,

  dealers: '/dealers',
  dealersCreate: '/dealers/create',
  dealer: (id = ':id') => `/dealers/${id}`,

  modules: '/modules',
  modulesCreate: '/modules/create',
  module: (id = ':id') => `/modules/${id}`,

  leasings: '/leasing',
  leasingCreate: '/leasing/create',
  leasing: (id = ':id') => `/leasing/${id}`,

  systemNotifications: '/system-notifications',
  systemNotificationsCreate: '/system-notifications/create',
  systemNotification: (id = ':id') => `/system-notifications/${id}`
};

export const getFullAdminRoute = (route: string) =>
  `${ADMIN_ROUTES.root}${route}`;
