import './clientInfoHeaderForm.scss';

import { FC } from 'react';

type TProps = {
  clientData: {
    firstName: string;
    lastName: string;
    middleName: string;
    iin: string;
    telNum: string;
  };
};

export const ClientInfoFormHeader: FC<TProps> = ({ clientData }) => {
  return (
    <div className="container">
      <div
        className="client_info"
        // style={{
        //   maxWidth: `${current === 0 ? '1120px' : ''}`
        // }}
      >
        <div>
          <h2
          // className={`${current === 0 ? 'editable' : ''} `}
          // onClick={openEditModal}
          >
            {`${clientData.lastName} ${clientData.firstName} ${clientData.middleName}`}
          </h2>
          <p>ИИН: {clientData.iin}</p>
        </div>
        <div style={{ minWidth: '225px' }}>
          <h2>{clientData.telNum}</h2>
          <p>Номер телефона</p>
        </div>
        {/*{current !== 0 && (*/}
        {/*<div>*/}
        {/*    <h2>{loan.auto}</h2>*/}
        {/*    <p>{validateNumberInputValue(loan.amount, 9, true)} ₸</p>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*<div>*/}
        {/*  /!*<h2>ID #{clientData.id}</h2>*!/*/}
        {/*  /!*<p onClick={openReportModal}>Сообщить о проблеме</p>*!/*/}
        {/*</div>*/}
      </div>
      {/*<div className="content">*/}
      {/*  /!*{current === 0 && (*!/*/}
      {/*  /!*  <OrderStep createRequest={handleNextStep} clientInfo={clientData} />*!/*/}
      {/*  /!*)}*!/*/}
      {/*  /!*{current === 1 && <OfferStep handleAcceptOffer={handleNextStep} />}*!/*/}
      {/*  /!*{current === 2 && <FinancingStep />}*!/*/}
      {/*</div>*/}
    </div>
  );
};
