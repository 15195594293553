import './modal.scss';

import { Modal } from 'antd';
import React from 'react';

type TCustomModalProps = {
  isModalOpen: boolean;
  handleOk: React.MouseEventHandler;
  handleCancel: (title: React.ReactNode | string) => void;
  children?: JSX.Element | React.ReactNode;
  footer?: React.ReactNode; //| ModalFooterRender;
  title: React.ReactNode | string;
  maskClosable?: boolean;
  editClass: string;
  width?: number;
};

export const CustomModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  children,
  footer,
  title,
  editClass,
  maskClosable,
  width
}: TCustomModalProps) => {
  return (
    <Modal
      width={width}
      centered={true}
      className={`CustomModal ${editClass}`}
      title={title}
      open={isModalOpen}
      maskClosable={maskClosable !== undefined ? maskClosable : true}
      onOk={handleOk}
      onCancel={() => handleCancel(title)}
      footer={footer}
    >
      {children}
    </Modal>
  );
};
