import { TPosition } from '@/types/position.type';

import { ApiConnector } from '../api.connector';

export class PositionsApi extends ApiConnector {
  public getAdminAllPositions(
    limit?: number,
    page?: number,
    searchTerm?: string,
    isActive?: boolean
  ): Promise<{
    total?: number;
    data: TPosition[];
  }> {
    return this.call({
      path: 'admin/positions/getAll',
      params: {
        limit,
        page,
        searchTerm,
        isActive
      }
    });
  }

  public createPosition(data: TPosition) {
    return this.call({
      path: 'admin/positions/create',
      method: 'post',
      body: data
    });
  }

  public updatePosition(data: TPosition) {
    return this.call({
      path: `admin/positions/${data.uuid}/update`,
      method: 'patch',
      body: data
    });
  }
  public getPositionById(uuid: string): Promise<TPosition> {
    return this.call({
      path: `admin/positions/${uuid}/findById`,
      method: 'get'
    });
  }
}
export const positionsApi = new PositionsApi();
