import { TClientData, TClientServerResponse } from '@/types/clients';

import { ApiConnector } from '../api.connector';

export class ClientsApi extends ApiConnector {
  public getAdminAllClients(
    limit?: number,
    page?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TClientServerResponse[];
  }> {
    return this.call({
      path: 'admin/clients/getClients',
      params: { limit, page, searchTerm }
    });
  }

  public getAdminClientById(id: string): Promise<TClientServerResponse> {
    return this.call({ path: `admin/clients/${id}` });
  }

  public updateClientById(body: TClientData): Promise<TClientData> {
    return this.call({
      path: `admin/clients/${body.uuid}`,
      method: 'patch',
      body
    });
  }
}

export const clientsApi = new ClientsApi();
