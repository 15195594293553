import {
  ADMIN_ROUTES,
  ROUTES,
  getFullAdminRoute
} from '@/components/Shared/routes/routes';

export const pageTitles = {
  [ROUTES.home]: 'Главная',
  [ROUTES.personal]: 'Личный кабинет',
  [ROUTES.faq]: 'Поддержка',
  [ROUTES.faqTemplate()]: 'Инструкция',

  [ROUTES.autoCredit]: 'Автокредитование',
  [ROUTES.autoCreditCreate]: 'Новая заявка',
  [ROUTES.autoCreditRequest()]: 'Заявка',
  [ROUTES.calculator]: 'Предварительный расчет',

  [ROUTES.insurances]: 'Страхование',
  [ROUTES.insurancesCreate]: 'Новая заявка',
  [ROUTES.insurancesRequest()]: 'Заявка',

  [ROUTES.settlement]: 'Урегулирование',
  [ROUTES.reports]: 'Отчеты',

  [ROUTES.leasing]: 'Лизинг',
  [ROUTES.leasingCreate]: 'Новая заявка',
  [ROUTES.leasingRequest()]: 'Заявка',

  [ROUTES.posCredit]: 'POS-кредитование',
  [ROUTES.posCreditCreate()]: 'Новая заявка',
  [ROUTES.posCreditRequest()]: 'Заявка',

  [ROUTES.clients]: 'Клиенты',
  [ROUTES.client()]: 'Клиент',
  [ROUTES.leads]: 'Лиды',
  [ROUTES.leadsUpdate()]: 'Редактирование лида',

  // Admin Titles
  [getFullAdminRoute(ADMIN_ROUTES.logs)]: 'Логи',
  [getFullAdminRoute(ADMIN_ROUTES.banks)]: 'Банки',
  [getFullAdminRoute(ADMIN_ROUTES.banksCreate)]: 'Банк',
  [getFullAdminRoute(ADMIN_ROUTES.bank())]: 'Банк',
  [getFullAdminRoute(ADMIN_ROUTES.products)]: 'Продукты',
  [getFullAdminRoute(ADMIN_ROUTES.productsCreate)]: 'Продукт',
  [getFullAdminRoute(ADMIN_ROUTES.product())]: 'Продукт',
  [getFullAdminRoute(ADMIN_ROUTES.auto)]: 'Авто',
  [getFullAdminRoute(ADMIN_ROUTES.brandsCreate)]: 'Бренд',
  [getFullAdminRoute(ADMIN_ROUTES.brand())]: 'Бренд',
  [getFullAdminRoute(ADMIN_ROUTES.modelsCreate)]: 'Модель',
  [getFullAdminRoute(ADMIN_ROUTES.model())]: 'Модель',
  [getFullAdminRoute(ADMIN_ROUTES.reports)]: 'Отчеты',
  [getFullAdminRoute(ADMIN_ROUTES.equipmentsCreate)]: 'Комлектации',
  [getFullAdminRoute(ADMIN_ROUTES.equipment())]: 'Комлектации',

  [getFullAdminRoute(ADMIN_ROUTES.faq)]: 'Поддержка',
  [getFullAdminRoute(ADMIN_ROUTES.faqCreate)]: 'Добавление страницы',
  [getFullAdminRoute(ADMIN_ROUTES.faqInfo())]: 'Изменение страницы',

  [getFullAdminRoute(ADMIN_ROUTES.clients)]: 'Клиенты',
  [getFullAdminRoute(ADMIN_ROUTES.client())]: 'Клиент',

  [getFullAdminRoute(ADMIN_ROUTES.dealers)]: 'Дилерские центры',
  [getFullAdminRoute(ADMIN_ROUTES.dealersCreate)]: 'Добавление ДЦ',
  [getFullAdminRoute(ADMIN_ROUTES.dealer())]: 'Изменение ДЦ',

  [getFullAdminRoute(ADMIN_ROUTES.users)]: 'Пользователи',
  [getFullAdminRoute(ADMIN_ROUTES.usersCreate)]: 'Добавление пользователя',
  [getFullAdminRoute(ADMIN_ROUTES.user())]: 'Изменение пользователя',

  [getFullAdminRoute(ADMIN_ROUTES.positionCreate)]: 'Добавление позиции',
  [getFullAdminRoute(ADMIN_ROUTES.position())]: 'Изменение позиции',

  [getFullAdminRoute(ADMIN_ROUTES.rolesActionCreate)]: 'Добавление роли',
  [getFullAdminRoute(ADMIN_ROUTES.rolesAction())]: 'Изменение роли',

  [getFullAdminRoute(ADMIN_ROUTES.modules)]: 'Модули',
  [getFullAdminRoute(ADMIN_ROUTES.modulesCreate)]: 'Добавление модуля',
  [getFullAdminRoute(ADMIN_ROUTES.module())]: 'Изменение модуля',

  [getFullAdminRoute(ADMIN_ROUTES.leasings)]: 'Лизинг',
  [getFullAdminRoute(ADMIN_ROUTES.leasingCreate)]: 'Лизинговая компания',
  [getFullAdminRoute(ADMIN_ROUTES.leasing())]: 'Лизинговая компания',

  [getFullAdminRoute(ADMIN_ROUTES.systemNotifications)]:
    'Системные уведомления',
  [getFullAdminRoute(ADMIN_ROUTES.systemNotificationsCreate)]:
    'Новое уведомление',
  [getFullAdminRoute(ADMIN_ROUTES.systemNotification())]:
    'Системное уведомление'
};
