import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { leasingAdminApi } from '@/integrations/adminApi/leasingAdminApi';
import {
  TCreateLeasing,
  TLeasingCompanyResponse,
  TLeasingData
} from '@/types/leasing';

type TLeasingSlice = {
  isLoading: boolean;
  total: number;
  leasings: TLeasingData[];
  leasingCompany: TLeasingCompanyResponse | null;
};

const initialState: TLeasingSlice = {
  isLoading: false,
  total: 0,
  leasings: [],
  leasingCompany: null
};

export const getAllLeasing = createAsyncThunk(
  'leasings',
  async ({
    limit,
    offset,
    searchTerm
  }: {
    limit?: number;
    offset?: number;
    searchTerm?: string;
  }) => {
    return leasingAdminApi.getAdminAllLeasing(limit, offset, searchTerm);
  }
);

export const createLeasing = createAsyncThunk(
  'admin/leasing/create',
  async (data: TCreateLeasing) => {
    return leasingAdminApi.createLeasing(data);
  }
);

export const updateLeasingAdmin = createAsyncThunk(
  'admin/leasing/update',
  async ({
    request_id,
    data
  }: {
    request_id: string;
    data: TCreateLeasing;
  }) => {
    return leasingAdminApi.updateAdminLeasing(request_id, data);
  }
);

export const getLeasingById = createAsyncThunk(
  'admin/getLeasingId',
  async (leasingId: string): Promise<TLeasingCompanyResponse> => {
    return await leasingAdminApi.getAdminLeasingById(leasingId);
  }
);

export const adminLeasingSlice = createSlice({
  name: 'leasing',
  initialState: initialState,
  reducers: {
    resetLeasingState(state) {
      state.leasingCompany = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeasing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLeasing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.total = action.payload.total;
        state.leasings = action.payload.data;
      })
      .addCase(getAllLeasing.rejected, (state) => {
        state.isLoading = false;
        state.leasings = [];
      })
      .addCase(getLeasingById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leasingCompany = action.payload;
      })
      .addCase(getLeasingById.pending, (state) => {
        state.isLoading = true;
        state.leasingCompany = null;
      })
      .addCase(getLeasingById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetLeasingState } = adminLeasingSlice.actions;
