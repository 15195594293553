import './mobile-menu.scss';

import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import BurgerMenuIcon from '@/media/icons/menu-burger.svg?react';
import { useAppDispatch, useAppSelector } from '@/store';
import { setMobileMenuItem, setSidebarExpanded } from '@/store/userInfo.slice';
import { TModule } from '@/types/user';

const badgeMaxCount = 9999;

const MobileNavbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { newLeadsCount } = useAppSelector((state) => state.leads);
  const { menu, mobileMenuItem } = useAppSelector((state) => state.userInfo);

  useEffect(() => {
    let newMenuItem: TModule | undefined;
    ['pos-credit', 'leasing', 'leads', 'clients', 'reports'].forEach((code) => {
      if (location.pathname.includes(code)) {
        newMenuItem = menu.find((item) => item.code === code);
      }
    });
    if (newMenuItem) {
      dispatch(setMobileMenuItem(newMenuItem));
    }
  }, [location.pathname, menu]);

  return (
    <div className="mobile-menu">
      {menu
        .filter((item) => ['auto-credit', 'insurances'].includes(item.code))
        .map((item) => (
          <NavLink
            key={item.code}
            to={`/${item.code}`}
            className={({ isActive }) =>
              `mobile-menu__link ${isActive ? 'mobile-menu__link--active' : ''}`
            }
          >
            <img
              className="mobile-menu__link-icon"
              src={item.icon}
              alt={item.name}
            />
            <span>{item.name}</span>
          </NavLink>
        ))}
      {mobileMenuItem && (
        <NavLink
          to={`/${mobileMenuItem.code}`}
          className={({ isActive }) =>
            `mobile-menu__link ${isActive ? 'mobile-menu__link--active' : ''}`
          }
        >
          <img
            className="mobile-menu__link-icon"
            src={mobileMenuItem.icon}
            alt={mobileMenuItem.name}
          />
          <span>{mobileMenuItem.name}</span>
          {mobileMenuItem.code === 'leads' && newLeadsCount > 0 && (
            <span className="mobile-menu__link-badge">
              {Math.min(newLeadsCount, badgeMaxCount) +
                (newLeadsCount > badgeMaxCount ? '+' : '')}
            </span>
          )}
        </NavLink>
      )}
      <button
        className="mobile-menu__button-icon"
        onClick={() => dispatch(setSidebarExpanded(true))}
      >
        <BurgerMenuIcon />
      </button>
    </div>
  );
};

export default MobileNavbar;
