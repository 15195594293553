import './info-badge.scss';

import React from 'react';

import InfoCircle from '@/media/svg/info-circle.svg?react';

interface IProps {
  className?: string;
  title: string;
}

const InfoBadge: React.FC<IProps> = ({ className, title }) => {
  return (
    <div className={`info-badge ${className ? className : ''}`}>
      <InfoCircle /> <span>{title}</span>
    </div>
  );
};

export default InfoBadge;
