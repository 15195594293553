import { TCreateProductAdminDto, TProduct } from '@/types/product.type';

import { ApiConnector } from '../api.connector';

export class ProductsApi extends ApiConnector {
  public getAdminProducts(
    limit: number,
    page: number,
    searchTerm: string,
    productType: string,
    bank: string,
    isActive?: boolean
  ): Promise<{
    total: number;
    data: TProduct[];
  }> {
    return this.call({
      path: 'admin/products',
      params: {
        limit,
        page,
        searchTerm,
        productType,
        isActive,
        bank
      }
    });
  }

  public createAdminProduct(product: TCreateProductAdminDto) {
    return this.call({ path: 'admin/products', method: 'post', body: product });
  }

  public async updateAdminProduct(
    productId: string,
    data: TCreateProductAdminDto
  ) {
    return this.call({
      path: `admin/products/${productId}`,
      method: 'patch',
      body: data
    });
  }

  public getAdminProductById(productId: string): Promise<TProduct> {
    return this.call({ path: `admin/products/${productId}` });
  }

  public async createPromoProductModels(data: {
    promo_product_uuid: string;
    brand_uuid: string;
    model_uuid: string;
  }) {
    return this.call({
      path: 'admin/promo-product-models/create',
      method: 'post',
      body: data
    });
  }
}

export const productsApi = new ProductsApi();
