import './promotion-modal.scss';

import { Modal } from 'antd';
import React, { useEffect, useRef } from 'react';

import { TPromotion } from '@/components/App/faq/types/promotions';
import { Button, CircledImage } from '@/components/Shared/ui';
import { BANKS_ICONS_NAMES } from '@/constants';
import ChevronDownIcon from '@/media/icons/chevron-down.svg?react';
import carImage from '@/media/images/car.png';

interface PromotionModalProps {
  visible: boolean;
  onClose: () => void;
  promotions: TPromotion[];
  selectedPromotion: TPromotion | null;
  setSelectedPromotion: (promo: TPromotion) => void;
  showAcknowledgment?: boolean;
  maskClosable?: boolean;
}

const PromotionModal: React.FC<PromotionModalProps> = ({
  visible,
  onClose,
  promotions,
  selectedPromotion,
  setSelectedPromotion,
  showAcknowledgment,
  maskClosable
}) => {
  const navElement = useRef<HTMLDivElement>(null);

  const totalPromotions = promotions.length;

  const currentIndex = selectedPromotion
    ? promotions.findIndex((p) => p.uuid === selectedPromotion?.uuid)
    : 0;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setSelectedPromotion(promotions[currentIndex - 1]);
    }
  };

  const handleNext = () => {
    if (currentIndex < promotions.length - 1) {
      setSelectedPromotion(promotions[currentIndex + 1]);
    }
  };

  const promotion = promotions[currentIndex];
  const bankIconName =
    BANKS_ICONS_NAMES[promotion?.bank?.name as keyof typeof BANKS_ICONS_NAMES];

  useEffect(() => {
    if (visible) {
      navElement.current?.classList.add('promotion-modal__nav--visible');
    } else {
      navElement.current?.classList.remove('promotion-modal__nav--visible');
    }
  }, [visible]);

  return (
    <>
      <Modal
        className="promotion-modal"
        open={visible && promotions.length > 0}
        onCancel={onClose}
        classNames={{
          content: 'promotion-modal__content',
          mask: 'promotion-modal__mask'
        }}
        footer={null}
        centered
        width={1056}
        maskClosable={maskClosable ?? false}
      >
        {showAcknowledgment && (
          <div className="promotion-modal__acknowledge">
            <p className="promotion-modal__acknowledge-text">
              Ознакомлен ({currentIndex + 1}/{totalPromotions})
            </p>
          </div>
        )}

        <div className="promotion-modal__wrapper">
          <div className="promotion-modal__header">
            <h2 className="promotion-modal__header-promo">{promotion?.name}</h2>
            <div className="promotion-modal__bank">
              <CircledImage
                src={promotion?.bank?.icon || bankIconName?.icon || ''}
                alt={promotion?.bank?.fullname}
                size={40}
              />
              <p className="promotion-modal__bank-name">
                {promotion?.bank?.fullname}
              </p>
            </div>
          </div>

          <div className="promotion-modal__models">
            <div
              className={`promotion-modal__car-list ${
                promotion?.Promo_Products_Models?.length > 9
                  ? 'promotion-modal__car-small'
                  : ''
              }`}
            >
              {promotion?.Promo_Products_Models?.map((model) => (
                <div
                  key={model?.model?.code}
                  className="promotion-modal__car-item"
                >
                  <img
                    className="promotion-modal__car-logo"
                    src={model?.model?.img_src || carImage}
                    alt={model.model?.name}
                    onError={(e) => (e.currentTarget.src = carImage)}
                  />
                  <span>{model?.model?.name}</span>
                </div>
              ))}
            </div>

            <div className="promotion-modal__info">
              <div className="promotion-modal__details">
                {promotion?.Promo_Products_Models[0]?.model.brand.img_src && (
                  <div className="promotion-modal__brand">
                    <img
                      className="promotion-modal__brand-icon"
                      src={
                        promotion?.Promo_Products_Models[0]?.model.brand.img_src
                      }
                      alt={
                        promotion?.Promo_Products_Models[0]?.model.brand.name
                      }
                    />
                  </div>
                )}

                {/* {promotion?.Products_Info?.length > 0 && (
                  <p className="promotion-modal__rate">
                    Процентная ставка:
                    <p className="promotion-modal__term">
                      от {promotion.Products_Info[0]?.initial_payment_from}%
                    </p>
                  </p>
                )} */}

                {/* TODO временное решение */}
                {(promotion?.promo_start_date || promotion?.promo_end_date) && (
                  <p className="promotion-modal__rate">
                    Срок действия:
                    <p className="promotion-modal__data">
                      {promotion?.promo_start_date
                        ? new Date(
                            promotion.promo_start_date
                          ).toLocaleDateString('ru-RU')
                        : ''}
                      {promotion?.promo_start_date && promotion?.promo_end_date
                        ? ' - '
                        : ''}
                      {promotion?.promo_end_date
                        ? new Date(promotion.promo_end_date).toLocaleDateString(
                            'ru-RU'
                          )
                        : ''}
                    </p>
                  </p>
                )}
              </div>

              <div className="promotion-modal__conditions">
                {promotion?.Products_Info?.map((info, index) => (
                  <div key={index} className="promotion-modal__condition">
                    <h4>Условие #{index + 1}</h4>
                    <div className="promotion-modal__condition-info">
                      <p className="promotion-modal__rate">
                        Первоначальный взнос:
                        <p className="promotion-modal__data">
                          от {info?.initial_payment_from}% до{' '}
                          {info?.initial_payment_to}%
                        </p>
                      </p>
                      <p className="promotion-modal__rate">
                        Сумма займа:
                        <p className="promotion-modal__data">
                          от{' '}
                          {Number(info?.loan_amount_from).toLocaleString(
                            'ru-RU'
                          )}{' '}
                          ₸ до{' '}
                          {Number(info?.loan_amount_to).toLocaleString('ru-RU')}{' '}
                          ₸
                        </p>
                      </p>
                      <p className="promotion-modal__rate">
                        Срок:
                        <p className="promotion-modal__data">
                          {info?.loan_period_to} мес.
                        </p>
                      </p>
                      <p className="promotion-modal__rate">
                        Подтверждение дохода:
                        <p className="promotion-modal__data">
                          {info?.is_proof_income ? 'Да' : 'Нет'}
                        </p>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {promotions.length > 1 && (
          <div ref={navElement} className="promotion-modal__nav">
            <Button
              className="promotion-modal__nav-button"
              variant="outlined"
              onClick={handlePrev}
              disabled={currentIndex === 0}
            >
              <ChevronDownIcon />
              <span className="promotion-modal__hidden">Предыдущий</span>
            </Button>

            {currentIndex === promotions.length - 1 ? (
              <Button
                variant="filled-red"
                className="promotion-modal__start-button"
                onClick={onClose}
              >
                <span className="promotion-modal__hidden">
                  Приступить к работе
                </span>
                <ChevronDownIcon />
              </Button>
            ) : (
              <Button
                className="promotion-modal__navigate-button"
                onClick={handleNext}
              >
                <span className="promotion-modal__hidden">Следующий</span>
                <ChevronDownIcon />
              </Button>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default PromotionModal;
