import { Middleware } from '@reduxjs/toolkit';
import { Socket, io } from 'socket.io-client';

import { formatBackBankOfferResponse } from '@/services/formatter/formatBackBankOfferResponse';
import { orderAppMapper } from '@/services/mappers/orderAppMapper';
import { setWsIsConnected } from '@/store/app.slice';
import {
  onSetSelectedBank,
  setIsFilesUpdated
} from '@/store/financingStep.slice';
import { setBadgeNewLeadsCount } from '@/store/leads.slice';
import { setOrderApp } from '@/store/newOrder.slice';
import {
  addNewNotification,
  setSystemNotifications
} from '@/store/notifications.slice';
import { setBankOffers } from '@/store/offerStep.slice';
import { TBankResponse } from '@/types/bankResponse.type';
import {
  SystemNotificationResponse,
  TNotificationServerResponse
} from '@/types/notifications';
import { TBankOffers } from '@/types/offers';
import { TRequest } from '@/types/request';

import { TRootState } from '../store';

let socket: Socket | null = null;

export const wsMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const isConnected = (storeAPI.getState() as TRootState).app.wsIsConnected;
  const user = (storeAPI.getState() as TRootState).userInfo.userInfo;

  const socketIoHost = `${process.env.REACT_APP_AUTH_URL}`
    .replace('https', 'wss')
    .replace(/(:\d\d\d)(\d)/, (match, start, digit) => `${start}${+digit}`);
  if (isAuthenticated && !socket && !isConnected && user?.uuid) {
    socket = io(socketIoHost, {
      transports: ['websocket'],
      auth: {
        token: user
      }
    });
    socket.on('connect', () => {
      storeAPI.dispatch(setWsIsConnected(true));
    });

    socket.on('connect_error', () => {
      storeAPI.dispatch(setWsIsConnected(false));
    });

    socket.on(
      'RequestsService_Updated',
      async (data: { payload: TRequest }) => {
        const { dicts } = (storeAPI.getState() as TRootState).dict;

        if (data.payload && dicts) {
          storeAPI.dispatch(setOrderApp(orderAppMapper(data.payload, dicts)));
        }
      }
    );

    socket.on(
      'ResponsesService_Updated',
      async (data: { payload: TBankResponse[] }) => {
        const { selectedBank } = (storeAPI.getState() as TRootState)
          .financingStep;
        const { requestId } = (storeAPI.getState() as TRootState).newOrder;
        if (requestId) {
          const selectedReq = data.payload.find(
            (item: TBankResponse) => item.request_uuid === requestId
          );
          if (selectedReq && Array.isArray(data.payload)) {
            const offer: TBankOffers = formatBackBankOfferResponse(selectedReq);
            storeAPI.dispatch(onSetSelectedBank(offer));
            const offers: TBankOffers[] = data.payload.map((item) =>
              formatBackBankOfferResponse(item)
            );
            storeAPI.dispatch(setBankOffers(offers));
          }
        }
        if (selectedBank && Array.isArray(data.payload)) {
          const selectedOffer = data.payload.find(
            (item: TBankResponse) => selectedBank.id === item.uuid
          );

          if (selectedOffer) {
            const offer = formatBackBankOfferResponse(selectedOffer);
            storeAPI.dispatch(onSetSelectedBank(offer));
            const offers: TBankOffers[] = data.payload.map((item) =>
              formatBackBankOfferResponse(item)
            );
            storeAPI.dispatch(setBankOffers(offers));
          }
        }
      }
    );

    socket.on(
      'System_Notification',
      (data: { payload: SystemNotificationResponse[] }) => {
        storeAPI.dispatch(setSystemNotifications(data.payload));
      }
    );

    socket.on('Leads', (data: { payload: number[] }) => {
      storeAPI.dispatch(setBadgeNewLeadsCount(data.payload?.[0] || 0));
    });

    socket.on(
      'FlashLogsService_Updated',
      (data: { payload: TNotificationServerResponse }) => {
        storeAPI.dispatch(addNewNotification(data.payload));
      }
    );

    socket.on('FilesService_Updated', (data: any) => {
      // const validMessage = serverNotificationsMapper(data);
      // store.dispatch(addNewNotification(validMessage));
      const selectedBank = (storeAPI.getState() as TRootState).financingStep
        .selectedBank;

      if (selectedBank && selectedBank.id === data.payload.request_uuid) {
        storeAPI.dispatch(setIsFilesUpdated(true));
      }
    });
  } else if ((!isAuthenticated || action.type === 'user/logout') && socket) {
    socket.disconnect();
    socket = null;
  }

  return next(action);
};
