import { TRoleAction } from '@/types/roleAction.type';

import { ApiConnector } from '../api.connector';

export class RolesActionsApi extends ApiConnector {
  public getAllRolesActions(
    limit?: number,
    page?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TRoleAction[];
  }> {
    return this.call({
      path: `admin/roles_action/getAll`,
      method: 'get',
      params: {
        limit,
        page,
        searchTerm
      }
    });
  }

  public createRolesAction(data: TRoleAction) {
    return this.call({
      path: `admin/roles_action/create`,
      method: 'post',
      body: data
    });
  }

  public updateRolesAction(data: TRoleAction) {
    return this.call({
      path: `admin/roles_action/${data.uuid}/update`,
      method: 'patch',
      body: data
    });
  }
  public getRolesById(uuid: string): Promise<TRoleAction> {
    return this.call({
      path: `admin/roles_action/${uuid}/findById`,
      method: 'get'
    });
  }
}

export const rolesActionsApi = new RolesActionsApi();
