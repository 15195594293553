import './Layout.scss';

import { jwtDecode } from 'jwt-decode';
import { FC, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';

import { DictTypes } from '@/constants';
import useResize from '@/hooks/useResize';
import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { getUserToken } from '@/services/user-hash';
import { useAppDispatch, useAppSelector } from '@/store';
import { setIsAuth } from '@/store/auth.slice';
import {
  getOptimizedDictionaries,
  getProductInsurance,
  getProductsDictionary
} from '@/store/dict.slice';
import {
  getUserMenu,
  setDealer,
  setJwtDecodedInfo
} from '@/store/userInfo.slice';
import { DecodedJWTToken } from '@/types/user';

import AdminMobileMenu from '../AdminPanel/NavBar/admin-mobile-menu';
import { ROUTES } from '../Shared/routes/routes';
import { AllurLoader } from '../Shared/ui';
import NotificationsSideBar from './Notifications/NotificationsSideBar';
import ErrorTemplate from './errors/error-template';
import { Header, Navbar } from './widgets';
import MobileNavbar from './widgets/mobile-menu';

export const Layout: FC = () => {
  const navigate = useNavigate();
  const { dicts } = useAppSelector((state) => state.dict);

  const dealers = dicts?.[DictTypes.Dealers]?.content;

  const { dealer, menu } = useAppSelector((state) => state.userInfo);
  const { isUserAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [wrapperWidth] = useResize({ element: '.layout .layout__wrapper' });

  const dealerCode = localStorage.getItem('dealer');

  const isAdmin = location.pathname.startsWith('/adminPage');

  useEffect(() => {
    if (dealerCode) {
      const dealerFind = dealers?.find(
        (_dealer) => String(_dealer.code) === dealerCode
      );

      const selectedDealer = dealerFind && {
        value: dealerFind.uuid,
        label: dealerFind.name,
        code: dealerFind.code
      };

      if (selectedDealer) {
        dispatch(setDealer(selectedDealer));
      }
    }
  }, [dealers, dealerCode]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isUserAuth && dealerCode === null) {
        navigate(ROUTES.login);
        dispatch(setIsAuth(false));
        clearLocalStorageHelper();
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isUserAuth, dealerCode]);

  useEffect(() => {
    if (dealer?.value) dispatch(getUserMenu(dealer.value));
  }, [dispatch, dealer?.value]);

  useEffect(() => {
    const accessToken = getUserToken().accessToken;
    if (accessToken) {
      const data = jwtDecode<DecodedJWTToken>(accessToken || '');
      dispatch(setJwtDecodedInfo(data));
      if (!dicts) dispatch(getOptimizedDictionaries());
      dispatch(getProductsDictionary());
      dispatch(getProductInsurance());
    }
  }, []);

  return (
    <div className="layout">
      <Navbar />

      <div
        className="layout__wrapper"
        style={{
          // @ts-ignore
          '--wrapper-width': `${wrapperWidth}px`
        }}
      >
        <Header />

        <main className="layout__main">
          <ErrorBoundary fallback={<ErrorTemplate type="oops" />}>
            <Suspense fallback={<AllurLoader fullScreen={true} />}>
              {!menu.length ? <AllurLoader fullScreen={true} /> : <Outlet />}
            </Suspense>
          </ErrorBoundary>
        </main>
        {isAdmin ? <AdminMobileMenu /> : <MobileNavbar />}
      </div>

      <NotificationsSideBar />
    </div>
  );
};
