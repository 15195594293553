import './admin-mobile-menu.scss';

import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ROUTES } from '@/components/Shared/routes/routes';
import BurgerMenuIcon from '@/media/icons/menu-burger.svg?react';
import { useAppDispatch, useAppSelector } from '@/store';
import { setAdminMobileMenu, setSidebarExpanded } from '@/store/userInfo.slice';

import { adminSideBarItems } from '../AdminNavBar';

const AdminMobileMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { adminMobileMenu } = useAppSelector((state) => state.userInfo);

  const selectedMenuItem = adminSideBarItems.find(
    (item) => item.key === adminMobileMenu
  );

  useEffect(() => {
    const excludedKeys = [
      '/adminPage/dealers',
      '/adminPage/banks',
      ROUTES.autoCredit
    ];

    const foundItem = adminSideBarItems
      .filter((elem) => !excludedKeys.includes(elem.key))
      .find((item) => location.pathname.includes(item.key));

    const newMenuItemKey = foundItem?.key;

    if (newMenuItemKey) {
      dispatch(setAdminMobileMenu(newMenuItemKey));
    }
  }, [location.pathname, adminSideBarItems]);

  return (
    <div className="admin-mobile-menu">
      {adminSideBarItems
        .filter((item) =>
          ['/adminPage/dealers', '/adminPage/banks'].includes(item.key)
        )
        .map((item) => (
          <NavLink
            key={item.key}
            to={item.key.replace(/\/(\/)/, '$1')}
            className={({ isActive }) =>
              `admin-mobile-menu__link ${isActive ? 'admin-mobile-menu__link--active' : ''}`
            }
          >
            {item.icon}
            <span>{item.label}</span>
          </NavLink>
        ))}
      {selectedMenuItem && (
        <NavLink
          to={selectedMenuItem.key}
          className={({ isActive }) =>
            `admin-mobile-menu__link ${isActive ? 'admin-mobile-menu__link--active' : ''}`
          }
        >
          {selectedMenuItem.icon}
          <span>{selectedMenuItem.label}</span>
        </NavLink>
      )}
      <button
        className="admin-mobile-menu__button-icon"
        onClick={() => dispatch(setSidebarExpanded(true))}
      >
        <BurgerMenuIcon />
      </button>
    </div>
  );
};

export default AdminMobileMenu;
