import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete } from '@/components/Shared/ui';
import { DictTypes } from '@/constants';
import { useAppSelector } from '@/store';

import { IFormInput } from '../../types/form-types';

const Dealer: React.FC = () => {
  const { dicts } = useAppSelector((state) => state.dict);

  const dealers = dicts?.[DictTypes.Dealers]?.content;
  const { userInfo } = useAppSelector((state) => state.userInfo);

  const { control, setFocus } = useFormContext<IFormInput>();

  const dealerOptions = useMemo(
    () =>
      userInfo?.Users_Dealers && userInfo.Users_Dealers.length > 0
        ? userInfo.Users_Dealers.map((dealer) => ({
            value: String(dealer.dealer.code),
            label: dealer.dealer.name
          }))
        : dealers && dealers.length > 0
          ? dealers
              .filter((item) => item.is_active)
              .map((dealer) => ({
                value: String(dealer.code),
                label: dealer.name
              }))
          : [],
    [userInfo?.Users_Dealers, dealers]
  );

  useEffect(() => {
    setFocus('dealer');
  }, []);

  return (
    <Controller
      control={control}
      name="dealer"
      rules={{
        required: 'Пожалуйста, выберите значение',
        validate: (value) => {
          if (!dealerOptions.find((item) => item.label === value))
            return 'Пожалуйста, выберите значение';
        }
      }}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          label="Дилерский центр"
          value={field.value ?? ''}
          options={dealerOptions}
          error={error?.message}
          classes={{ root: 'login__field' }}
          isRequired
        />
      )}
    />
  );
};

export default Dealer;
