import './system-notifications.scss';

import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store';

import { ROUTES } from '../../routes/routes';
import { NOTIFICATION_TYPES } from './constants';

interface IProps {
  marginFrom?: 'top' | 'bottom';
}

const SystemNotifications: React.FC<IProps> = ({ marginFrom = 'bottom' }) => {
  const location = useLocation();
  const currentModule = useAppSelector((state) =>
    state.userInfo.menu.find(
      (item) =>
        (location.pathname === ROUTES.home && item.code === 'main') ||
        location.pathname.includes(item.code)
    )
  );
  const systemNotifications = useAppSelector(
    (state) => state.notifications.systemNotifications
  );
  const notifications = systemNotifications.filter(
    (item) => item.moduleUuid === currentModule?.uuid
  );

  if (notifications.length === 0) return <></>;

  const rootClasses = [
    'system-notifications',
    marginFrom === 'bottom'
      ? 'system-notifications--margin-bottom'
      : 'system-notifications--margin-top'
  ].join(' ');

  return (
    <div className={rootClasses}>
      {notifications.map((item) => {
        const notificationStyle =
          NOTIFICATION_TYPES[item.type as keyof typeof NOTIFICATION_TYPES];
        return (
          <div
            key={item.uuid}
            className="system-notifications__notification"
            style={{
              borderLeftColor: notificationStyle.borderColor,
              color: notificationStyle.textColor,
              boxShadow: `0 0 3px 0px ${notificationStyle.borderColor}`,
              backgroundColor: notificationStyle.backgroundColor
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        );
      })}
    </div>
  );
};

export default SystemNotifications;
