import './car-info-modal.scss';

import { Modal } from 'antd';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Autocomplete } from '@/components/Shared/ui';

import useCarInfo, { CarInfoInterface, KeyToUse } from './hooks/useCarInfo';

interface IProps {
  isOpened: boolean;
  onClose: () => void;
  onSave: (values: SubmitCarInfoProps) => void;
  keyToUse: KeyToUse;
  defaultValues?: CarInfoInterface;
  dealersCity?: string;
}

const CarInfoModal: React.FC<IProps> = ({
  isOpened,
  onClose,
  onSave,
  keyToUse,
  defaultValues,
  dealersCity
}) => {
  const {
    models,
    possibleModels,
    possibleMarks,
    possibleDealers,
    years,
    handleMarkChanged
  } = useCarInfo({
    keyToUse,
    defaultValues
  });

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    handleSubmit
  } = useForm<CarInfoInterface>({ mode: 'all', delayError: 200 });

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues,
        year: defaultValues.year || new Date().getFullYear().toString()
      });
    }
  }, [defaultValues]);

  const onSubmit = (values: CarInfoInterface) => {
    const model = possibleModels.find((item) => values.model === item.label);

    onSave({
      mark: {
        [keyToUse]:
          possibleMarks.find((item) => values.mark === item.label)?.value || '',
        name: values.mark
      },
      model: {
        [keyToUse]: model?.value || '',
        name: values.model,
        isCommercial: !!model?.isCommercial
      },
      year: values.year,
      dealer: values.dealer
        ? {
            [keyToUse]:
              possibleDealers.find((item) => values.dealer === item.label)
                ?.value || '',
            name: values.dealer
          }
        : undefined
    });

    reset({
      mark: '',
      model: '',
      year: ''
    });
  };

  const handleClose = () => {
    onClose();
    reset({
      mark: '',
      model: '',
      year: ''
    });
  };

  return (
    <Modal
      maskClosable={true}
      wrapClassName="car-info-modal-wrapper"
      className="car-info-modal"
      classNames={{
        header: 'car-info-modal__header'
      }}
      title="Марка, модель и год выпуска"
      open={isOpened}
      onClose={handleClose}
      onCancel={handleClose}
      footer={<></>}
    >
      <form
        className="car-info-modal__form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Controller
          control={control}
          name="mark"
          rules={{
            required: 'Пожалуйста, введите данные',
            minLength: { value: 1, message: 'Пожалуйста, введите данные' },
            validate: (value) => {
              const mark = possibleMarks.find((item) => item.label === value);
              if (!mark) return 'Пожалуйста выберите существующую марку';
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              value={field.value ?? ''}
              label="Марка авто"
              onChange={(value) => {
                field.onChange(value);
                const mark = possibleMarks.find((item) => item.label === value);
                if (mark) {
                  handleMarkChanged(mark.value);
                  setValue('model', '');
                  setValue('dealer', '');
                }
              }}
              options={possibleMarks}
              error={error?.message}
              autoComplete="off"
              isRequired
            />
          )}
        />
        <Controller
          control={control}
          name="model"
          rules={{
            required: 'Пожалуйста, введите данные',
            minLength: { value: 1, message: 'Пожалуйста, введите данные' },
            validate: (value) => {
              const model = possibleModels.find((item) => item.label === value);
              if (!model) return 'Пожалуйста выберите существующую модель';
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              value={field.value ?? ''}
              label="Модель авто"
              options={errors?.mark ? [] : models}
              error={error?.message}
              autoComplete="off"
              isRequired
            />
          )}
        />
        <Controller
          control={control}
          name="year"
          rules={{
            required: 'Пожалуйста, введите данные',
            minLength: {
              value: 4,
              message: 'Год должен содержать 4 цифры'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              type="tel"
              value={field.value ?? ''}
              label="Год выпуска"
              onChange={(value) => {
                if (value.match(/\D/) || value.length > 4) return;
                field.onChange(value);
              }}
              options={years}
              error={error?.message}
              autoComplete="off"
              isRequired
            />
          )}
        />
        {dealersCity && (
          <Controller
            control={control}
            name="dealer"
            rules={{
              required: 'Пожалуйста, введите данные',
              minLength: { value: 1, message: 'Пожалуйста, введите данные' },
              validate: (value) => {
                const dealer = possibleDealers.find(
                  (item) => item.label === value
                );
                if (!dealer)
                  return 'Пожалуйста выберите существующую торговую точку';
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                value={field.value ?? ''}
                label="Торговая точка"
                options={errors?.mark ? [] : possibleDealers}
                error={error?.message}
                autoComplete="off"
                isRequired
              />
            )}
          />
        )}

        <div className="car-info-modal__actions">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            className="car-info-modal__button car-info-modal__cancel"
          >
            Отмена
          </button>
          <button
            type="submit"
            className="car-info-modal__button car-info-modal__save"
            disabled={Object.values(errors).length !== 0}
          >
            Сохранить
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CarInfoModal;

interface SubmitCarInfoProps {
  mark: {
    uuid?: string;
    code?: string;
    name: string;
  };
  model: {
    uuid?: string;
    code?: string;
    name: string;
    isCommercial: boolean;
  };
  year: string;
  dealer?: {
    uuid?: string;
    code?: string;
    name: string;
  };
}
