import './order-history.scss';

import { Modal, TablePaginationConfig } from 'antd';
import { useState } from 'react';

import { Button, Table } from '@/components/Shared/ui';

import useAutoCreditHistory from './hooks/useAutoCreditHistory';
import { historyTableDataSource } from './table-data-source';

interface IProps {
  historyType: 'auto-credit';
  isOpened: boolean;
  onClose: (isOpened: boolean) => void;
}

const OrderHistory: React.FC<IProps> = ({ historyType, isOpened, onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { autoCreditData, totalAutoCreditData, autoCreditLoading } =
    useAutoCreditHistory({
      shouldRequest: historyType === 'auto-credit' && isOpened
    });

  const handleClose = () => {
    onClose(false);
  };

  const handlePaginationChanged = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  return (
    <Modal
      centered
      maskClosable={true}
      className="order-history"
      title="История изменений"
      open={isOpened}
      onClose={handleClose}
      onCancel={handleClose}
      footer={<></>}
      width={800}
    >
      <div className="order-history__table">
        <Table
          dataSource={autoCreditData}
          columns={historyTableDataSource}
          rowKey={(record) => record.date}
          pagination={{
            position: ['bottomLeft'],
            showSizeChanger: false,
            total: totalAutoCreditData,
            defaultPageSize: 10,
            current: currentPage
          }}
          loading={autoCreditLoading}
          onChange={handlePaginationChanged}
        />
      </div>

      <div className="order-history__actions">
        <Button onClick={handleClose}>Закрыть</Button>
      </div>
    </Modal>
  );
};

export default OrderHistory;
