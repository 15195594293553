import './multiselect.scss';

import { SwapOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { forwardRef, useMemo, useState } from 'react';

import ChevronDownIcon from '@/media/icons/chevron-down.svg?react';

import type { IInputProps } from '../input';
import Input from '../input';
import OutsideClickHandler from '../outside-click-handler';
import useControls from './hooks/useControls';
import usePopup from './hooks/usePopup';
import useSelect from './hooks/useSelect';

export const MULTISELECT_GROUP = 'multiselect-group';

interface IProps extends Omit<IInputProps, 'onChange' | 'value'> {
  options: IMultiselectOptions[];
  selectedValues?: IMultiselectOption[];
  onChange: (
    value: IMultiselectOption | null,
    selectedValues: IMultiselectOption[]
  ) => void;
}

const Multiselect = forwardRef<HTMLInputElement, IProps>(
  (
    { selectedValues, options: propsOptions, onChange, isFrozen, ...rest },
    forwardRef
  ) => {
    const [focused, setFocused] = useState(false);
    const [focusTriggered, setFocusTriggered] = useState(false);

    const options: IMultiselectOption[] = useMemo(() => {
      return propsOptions.reduce((acc, option) => {
        if ('groupTitle' in option) {
          acc.push({ label: option.groupTitle, value: MULTISELECT_GROUP });
          acc.push(...option.options);
          return acc;
        }

        acc.push(option);
        return acc;
      }, [] as IMultiselectOption[]);
    }, [propsOptions]);
    const hasOptionGroups = propsOptions.some(
      (option) => 'groupTitle' in option
    );

    const {
      filteredOptions,
      checkedFilter,
      searchValue,
      setSearchValue,
      toggleCheckedFilter
    } = useControls({
      selectedValues,
      options
    });

    const { bindInput, bindOptions, bindOption, selectedIndex } = useSelect({
      selectedValues,
      onChange,
      defaultOptions: filteredOptions,
      setFocused
    });

    const { topPosition, handlePopupToggle } = usePopup({
      focused,
      setFocused,
      listElement: bindOptions.ref.current,
      isFrozen
    });

    const handleAll = () => {
      if (options.length === selectedValues?.length) onChange(null, []);
      else onChange(options[0], options);
    };

    const fieldClassName = [rest.className ?? '', 'multiselect__field'].join(
      ' '
    );

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setSearchValue('');
          setFocused(false);
        }}
      >
        <div className="multiselect">
          <Input
            {...rest}
            readOnly
            inputMode="none"
            ref={forwardRef}
            className={fieldClassName}
            value={bindInput.value ?? ''}
            autoComplete="off"
            endContent={
              <span
                className={`multiselect__arrow ${
                  focused ? 'multiselect__arrow--rotated' : ''
                }`}
              >
                <ChevronDownIcon />
              </span>
            }
            onChange={() => null}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && focused) e.preventDefault();
              if (e.key === 'Tab') setFocused(false);
              bindInput.onKeyDown(e);
              rest.onKeyDown?.(e);
            }}
            onFocus={(e) => {
              setFocusTriggered(true);
              handlePopupToggle();
              if (focused) setSearchValue('');
              rest.onFocus?.(e);
            }}
            onClick={(e) => {
              if (!focusTriggered) {
                handlePopupToggle();
                if (focused) setSearchValue('');
              }
              setFocusTriggered(false);
              rest.onClick?.(e);
            }}
            isFrozen={isFrozen}
          />
          <div
            className={`multiselect__popup ${
              focused ? 'multiselect__popup--shown' : ''
            } ${topPosition ? 'multiselect__popup--to-top' : ''}`}
          >
            <div className="multiselect__controls">
              <Checkbox
                indeterminate={
                  (selectedValues?.length || 0) > 0 &&
                  options.length !== selectedValues?.length
                }
                checked={options.length === selectedValues?.length}
                onChange={handleAll}
              />
              <input
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="multiselect__search"
                placeholder="Поиск"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && focused) e.preventDefault();
                  bindInput.onKeyDown(e);
                }}
              />
              <button
                type="button"
                onClick={toggleCheckedFilter}
                className={`multiselect__filter ${
                  checkedFilter ? 'multiselect__filter--selected' : ''
                } `}
              >
                <SwapOutlined />
              </button>
            </div>

            <ul {...bindOptions} className="multiselect__list">
              {filteredOptions.map((option, index) =>
                option.value === MULTISELECT_GROUP ? (
                  <li
                    key={option.label}
                    style={{ padding: option.label ? undefined : '0' }}
                    className="multiselect__group-item"
                  >
                    {option.label}
                  </li>
                ) : (
                  <li
                    className={`multiselect__item ${
                      selectedIndex === index
                        ? 'multiselect__item--selected'
                        : ''
                    } ${hasOptionGroups ? 'multiselect__item--with-group' : ''}`}
                    key={option.label + option.value}
                    {...bindOption}
                  >
                    <Checkbox
                      checked={
                        selectedValues?.find(
                          (selected) => selected.value === option.value
                        ) !== undefined
                      }
                    />
                    {option.label}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
);

Multiselect.displayName = 'Multiselect';

export default Multiselect;

export type IMultiselectOptions = IMultiselectOption | IMultiselectGroup;

export interface IMultiselectOption {
  value: string;
  label: string;
}

export interface IMultiselectGroup {
  groupTitle: string;
  options: IMultiselectOption[];
}
