import altynLogo from './media/icons/altyn_logo.png';
import bckLogo from './media/icons/bck_Logo.png';
import berekeLogo from './media/icons/bereke_logo.png';
import check from './media/icons/check.svg';
import eurasLogo from './media/icons/euras_logo.png';
import fileDownload from './media/icons/file-download.svg';
import forteLogo from './media/icons/forte_logo.png';
import freedomlogo from './media/icons/freedom_Logo.png';
import halykLogo from './media/icons/halyk_logo.png';
import info from './media/icons/info.png';
import kmfLogo from './media/icons/kmf_logo.png';
import nur from './media/icons/nur.svg';
import phone from './media/icons/phone.svg';
import plus from './media/icons/plus.svg';
import printer from './media/icons/printer.png';
import questionIcon from './media/icons/question-circle-outlined.png';
import settingIcon from './media/icons/settings.png';
import shinHanLogo from './media/icons/shinhan-bank.png';
import warning from './media/icons/warning-fill.png';
import calculatorPlaceholder from './media/images/calculatorPlaceholder.png';
import logoBg from './media/login_bg.jpg';
import AllurFinanceLogo from './media/logo.png';
import expandLogo from './media/logo_expand.png';
import logoAllurRed from './media/svg/logo_red.svg';

export const pathLogo = AllurFinanceLogo;

export const pathCalculatorPlaceholder = calculatorPlaceholder;
export const pathExpandLogo = expandLogo;
export const pathSettingIcon = settingIcon;
export const pathQuestionicon = questionIcon;
export const pathFreedomlogo = freedomlogo;
export const pathBckLogo = bckLogo;
export const pathBerekeLogo = berekeLogo;
export const pathEurasLogo = eurasLogo;
export const pathForteLogo = forteLogo;
export const pathHalykLogo = halykLogo;
export const pathKmfLogo = kmfLogo;
export const pathLogoBg = logoBg;
export const pathLogoAllurRed = logoAllurRed;
export const infoIcon = info;
export const printerLogo = printer;
export const warningIcon = warning;
export const plusIcon = plus;
export const checkIcon = check;
export const phoneIcon = phone;
export const fileDownloadIcon = fileDownload;

export enum DictTypes {
  PropertyTypes = 'Property_Types', //Вид собственности
  ProductsTypes = 'Product_Types', //Типы продуктов
  MainActivities = 'Main_Activities', //Основной вид деятельности
  DocumentIssuringAuthorities = 'Document_Issuing_Authorities', // Кем выдан
  DocumentTypes = 'Document_Types', // Тип документа
  ResidentStatuses = 'Resident_Statuses', // Статус резидента
  MaritalStatuses = 'Marital_Statuses', // Семейное положение
  SocialStatuses = 'Social_Statuses', // Социальный статус
  OrganizationTypes = 'Organization_Types', // Вид организации
  Insurances = 'Insurances', //Страховые компании
  EducationTypes = 'Education_Types', // Образование
  // PropertyTypes = 'Property_Types', // Тип дома
  CompanyActivityTypes = 'Company_Activity_Types', // Сфера деятельности организации
  RepaymentTypes = 'Repayment_Types', // Схема оплаты
  Statuses = 'Statuses', // Статусы
  VehicleRegistrationCompanies = 'Vehicle_Registration_Companies',
  Genders = 'Genders', // Пол
  LoanTerms = 'Loan_Terms', // Срок займа
  EngineTypes = 'Engine_Types', // Тип двигателя
  SalePoints = 'Sale_Points', // Точки продаж
  Dealers = 'Dealers', // Диллеры
  DealersBrands = 'Dealers_Brands', // Дилерские бренды
  AutoTypes = 'Auto_Types', // Тип авто
  PaymentDates = 'Payment_Dates', // Дата платежа
  Colors = 'Colors', // Цвет авто
  AddContactsIsRelated = 'Add_Contacts_Is_Related', // Кем приходится
  Brands = 'Brands', // Марка
  Models = 'Models', // Модель
  Years = 'Years', // Год выпуска
  Countries = 'Countries', //Страны
  Products = 'Products', //Продукты (StockBoundaries)
  Banks = 'Banks', // Интегрированные Банки
  Dealears_Banks = 'Dealers_Banks', // Банки диллеров
  InsurancePayment = 'Insurance_Payment_Types', // способ оплаты
  InsuranсeInstallmentTypes = 'Insurance_Installment_Types', // тип оплаты
  Insurance_Installment_Periods = 'Insurance_Installment_Periods', // период рассрочки
  //InsurancePayment = 'Insurance_Payment_Type', // выбор оплты страхования
  PoscredProducts = 'Poscred_Products', // Тип Pos-cred продуктов
  Poscred_Bank_Products = 'Poscred_Bank_Products', // Продукт БВУ
  Integration_Types = 'Integration_Types',
  Drive_Types = 'Drive_Types', // тип привода
  Gearbox_Types = 'Gearbox_Types', //тип коробки
  Cities = 'Cities', // Список всех городов
  DealersCities = 'Dealers_Cities', // Список городов по активным дилерам
  DealersUsedDealers = 'Dealers_Used_Dealers' // Дилеры привязанные к дилерам
}

export type DictTypesKeys = `${DictTypes}`;

export const statusColors = {
  draft: {
    bgColor: '#313A49',
    textColor: 'white'
  },
  created: {
    bgColor: '#313A49',
    textColor: 'white'
  },
  approved: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  completed: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  declined: {
    bgColor: '#E03137',
    textColor: 'white'
  },
  waiting_bank_response: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  waiting_of_payment: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  error: {
    bgColor: '#FF2E1F',
    textColor: 'white'
  },
  financed: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  cancelled: {
    bgColor: '#FF2E1F',
    textColor: 'white'
  },
  waiting_of_financing: {
    bgColor: '#1f9aff',
    textColor: 'white'
  },
  waiting_for_upload_files: {
    bgColor: '#1f9aff',
    textColor: 'white'
  },
  vehicle_registration: {
    bgColor: '#1f9aff',
    textColor: 'white'
  },
  accepted_alternative: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  on_rework: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  on_verification: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  financed_pledge_missing: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  sms_verificating: {
    bgColor: '#023D3D',
    textColor: 'white'
  },
  pre_approved: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  mobile_client: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  financed_waiting_for_modification_before_pledge: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  refund_in_progress: {
    bgColor: '#a8a8a8',
    textColor: 'white'
  },
  refunded: {
    bgColor: '#a8a8a8',
    textColor: 'white'
  }
};

export const BANKS_COLORS = {
  halyk: '#00805F',
  eu_bank: '#FF006E',
  eu_bank_v2: '#FF006E',
  ffb: '#76B949',
  forte: '#4a0221',
  kmf: '#D4310F',
  bcc: '#DAAB6B',
  bereke: '#6F873E',
  altyn: '#E21F25',
  shinhan: '#0046FF'
};

export const BANKS_ICONS_NAMES = {
  halyk: { name: 'Халык Банк', icon: pathHalykLogo },
  kmf: { name: 'KMF', icon: pathKmfLogo },
  bereke: { name: 'Береке Банк', icon: pathBerekeLogo },
  shinhan: { name: 'Shinhan Банк', icon: shinHanLogo },
  bcc: { name: 'Банк Центр Кредит', icon: pathBckLogo },
  sapa: { name: 'SAPA', icon: '' },
  altyn: { name: 'Алтын Банк', icon: altynLogo },
  ffb: { name: 'Freedom Bank', icon: pathFreedomlogo },
  forte: { name: 'Forte Bank', icon: pathForteLogo },
  eu_bank: { name: 'Банк Евразийский', icon: pathEurasLogo },
  eu_bank_v2: { name: 'Банк Евразийский', icon: pathEurasLogo },
  nur: { name: '', icon: nur }
};

export const REGISTRATION_DUTY_COST = 46500;
export const WIDTH_FOR_OPENED_NAVBAR = 1340;
