import { useMemo, useState } from 'react';

import useDebouncedValue from '@/hooks/useDebouncedValue';

import { IMultiselectOption } from '../multiselect';

interface IProps {
  options: IMultiselectOption[];
  selectedValues?: IMultiselectOption[];
}

const useControls = ({ options, selectedValues }: IProps) => {
  const [checkedFilter, setCheckedFilter] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearch = useDebouncedValue(searchValue);

  const filteredOptions = useMemo(() => {
    let filtered = options;

    if (checkedFilter) {
      const checked: IMultiselectOption[] = [];
      const unchecked: IMultiselectOption[] = [];

      options.forEach((option) => {
        const isSelected =
          selectedValues?.find(
            (selected) => selected.value === option.value
          ) !== undefined;
        if (isSelected) checked.push(option);
        else unchecked.push(option);
      });

      filtered = [...checked, ...unchecked];
    }

    if (debouncedSearch) {
      filtered = options.filter((option) => {
        const label = option.label.toLowerCase();
        return label.includes(debouncedSearch.toLowerCase());
      });
    }

    return filtered;
  }, [options.length, selectedValues?.length, checkedFilter, debouncedSearch]);

  const toggleCheckedFilter = () => setCheckedFilter(!checkedFilter);

  return {
    filteredOptions,
    checkedFilter,
    searchValue,
    setSearchValue,
    toggleCheckedFilter
  };
};

export default useControls;
