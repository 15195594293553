import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DictTypesKeys } from '@/constants';

import { apiConnector } from '../integrations/api.connector';
import { TProductInfo } from '../types/productInfo';
import { TDict, TOrderStepStore } from '../types/store';
import { getRequestById } from './newOrder.slice';
import { changeRequestStatus } from './request.slice';

export const requestCreateUnverifiedDraft = createAsyncThunk(
  'orderStep/requestCreateUnverifiedDraft',
  async (
    data: {
      draftDto: any;
      dicts: Record<DictTypesKeys, TDict>;
    },
    { dispatch }
  ): Promise<any> => {
    try {
      const { draftDto, dicts } = data;
      const response: {
        request_uuid: string;
      } = await apiConnector.requestCreateUnverifiedDraft(draftDto);
      await dispatch(
        getRequestById({
          reqId: response?.request_uuid || '',
          dicts: dicts
        })
      );
      return response?.request_uuid || '';
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const createDraftFromCalc = createAsyncThunk(
  'orderStep/createDraftFromCalc',
  async (clientCalcUuid: string): Promise<any> => {
    try {
      return await apiConnector.createDraftFromCalc(clientCalcUuid);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const clientCreateCalc = createAsyncThunk(
  'orderStep/clientCreateCalc',
  async (data: {
    iin: string;
    clientPhone: string;
    firstName: string;
    middleName: string;
    lastName: string;
    type: 'WhatsApp' | 'SMS';
  }): Promise<any> => {
    try {
      return await apiConnector.clientCreateCalc(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const validateOTP = createAsyncThunk(
  'orderStep/validateOTP',
  async (
    data: { uuid: string; otp: string; dicts: Record<DictTypesKeys, TDict> },
    { dispatch }
  ): Promise<any> => {
    const responseData = (await apiConnector.validateOTP(data)) as any;
    if (responseData.result) {
      await dispatch(
        changeRequestStatus({ reqId: data.uuid, newStatus: 'draft' })
      );
      await dispatch(getRequestById({ reqId: data.uuid, dicts: data.dicts }));
    }
    return responseData;
  }
);

export const validateCalcOTP = createAsyncThunk(
  'orderStep/validateCalcOTP',
  async (data: { uuid: string; otp: string }): Promise<any> => {
    try {
      return await apiConnector.validateCalcOTP(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const retryValidateOTP = createAsyncThunk(
  'orderStep/retryOTP',
  async (
    data: { uuid: string; clientPhone: string; type: 'WhatsApp' | 'SMS' },
    { dispatch }
  ): Promise<any> => {
    try {
      const responseData = (await apiConnector.retryOTP(data)) as any;
      if (responseData.result) {
        await dispatch(
          changeRequestStatus({ reqId: data.uuid, newStatus: 'draft' })
        );
      }
      return responseData;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TOrderStepStore = {
  otpCode: '',
  otpValid: null,
  requestId: '',
  clientCalcId: '',
  isLoading: false,
  productInfo: {
    paymentScheme: null,
    chosenDealer: '',
    requestAmount: '',
    carYear: '',
    markModelYear: {
      brand: null,
      model: null,
      year: null
    },
    engineType: null,
    dealer: null,
    costCar: '',
    autoType: null,
    initFee: '',
    loanTerm: null,
    proofInCome: true,
    stocksBonds: null
  },
  additionalInfo: {
    files: null
  },
  personalInfo: {
    jobAddress: '',
    sex: '',
    docType: '',
    salary: '',
    addContactsIsRelated: '',
    addContactsTelNum: '',
    children: '',
    additionalSalary: '',
    companyName: '',
    jobTitle: '',
    socialStatus: '',
    workTelNumber: '',
    addContactsFullName: '',
    companyType: '',
    familyStatus: '',
    dateBirth: null,
    spouseInfo: {
      spouseIin: '',
      spouseFullName: '',
      spousePlaceBirth: '',
      spouseTelNum: '',
      spouseDateBirth: null,
      spouseResidentStatus: '',
      spouseCompanyName: '',
      spouseSalary: '',
      spouseAddSalary: '',
      isMarried: false
    }
  }
};

export const orderStepSlice = createSlice({
  name: 'orderStepSlice',
  initialState,
  reducers: {
    onSetProductInfo(state, action: PayloadAction<TProductInfo>) {
      state.productInfo = action.payload;
    },
    onSetOrderOtpCode(state, action: PayloadAction<string>) {
      state.otpCode = action.payload;
    },
    onSetOtpRequestToInitial(state) {
      state.otpValid = null;
      state.requestId = '';
    },
    onSetRequestId(state, action: PayloadAction<string>) {
      state.requestId = action.payload;
    },
    onResetProductInfo(state) {
      state.productInfo = initialState.productInfo;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        requestCreateUnverifiedDraft.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.isLoading = false;
          state.requestId = action.payload;
        }
      )
      .addCase(requestCreateUnverifiedDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestCreateUnverifiedDraft.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createDraftFromCalc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requestId = action.payload?.request_uuid || '';
      })
      .addCase(createDraftFromCalc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDraftFromCalc.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(clientCreateCalc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clientCalcId = action.payload?.client_calculation_uuid || '';
      })
      .addCase(clientCreateCalc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(clientCreateCalc.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(validateOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.otpValid = action.payload?.result || false;
      })
      .addCase(validateOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateOTP.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(validateCalcOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.otpValid = action.payload?.result || false;
      })
      .addCase(validateCalcOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateCalcOTP.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(retryValidateOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(retryValidateOTP.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {
  onSetProductInfo,
  onSetOrderOtpCode,
  onSetOtpRequestToInitial,
  onSetRequestId,
  onResetProductInfo
} = orderStepSlice.actions;
