import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { IdeApi } from '@/integrations/adminApi/ideApi';
import { AuthAdminApi } from '@/integrations/adminApi/refreshApi';
import { SalePointsApi } from '@/integrations/adminApi/salePointsApi';
import { SqlExecutorApi } from '@/integrations/adminApi/sqlExecutorApi';
import { DownloadFilesApi } from '@/integrations/downloadFilesApi';
import { LitroApi } from '@/integrations/litroApi';
import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { getUserToken, setUserToken } from '@/services/user-hash';

import { PosCredApi } from './adminApi/posCredApi';

let isRefreshing = false;

const indexApi = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`
});

const ideAxios = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`
});

indexApi.interceptors.request.use((config) => {
  try {
    const accessToken = getUserToken().accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  } catch (e) {
    console.error(e);
  }
  return config;
});

const refreshTokenAxios = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`
});

const adminRefreshApi = new AuthAdminApi(refreshTokenAxios);

refreshTokenAxios.interceptors.request.use((config) => {
  try {
    const refreshToken = getUserToken().refreshToken;
    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    } else {
      // localStorage.clear();
      clearLocalStorageHelper();
    }
  } catch (e) {
    console.error(e);
  }
  return config;
});

indexApi.interceptors.response.use(
  async (response) => response,
  async (error: AxiosError) => {
    try {
      const originalRequest = error.config as InternalAxiosRequestConfig;

      if (
        (error.response && error.response.status === 403) ||
        error.response?.status === 401
      ) {
        if (!isRefreshing) {
          isRefreshing = true;

          const newResponse = await adminRefreshApi.refreshToken();
          if (newResponse.status === 200) {
            const { emailAllur } = getUserToken();
            setUserToken({
              accessToken: newResponse.data.accessToken,
              refreshToken: newResponse.data.refreshToken,
              emailAllur
            });
            originalRequest.headers.Authorization = `Bearer ${newResponse.data.accessToken}`;
            isRefreshing = false;
            return indexApi(originalRequest);
          }
        }
      }
      isRefreshing = false;
      return Promise.reject(error);
    } catch {
      isRefreshing = false;
      clearLocalStorageHelper();

      return Promise.reject(error);
    }
  }
);

export const downloadFilesApi = new DownloadFilesApi(indexApi);
export const sqlExecutor = new SqlExecutorApi(indexApi);
export const salePointsApi = new SalePointsApi(indexApi);
export const posCredApi = new PosCredApi(indexApi);
export const ideApi = new IdeApi(ideAxios);
export const litroApi = new LitroApi(indexApi);
