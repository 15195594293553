import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { productsApi } from '@/integrations/adminApi/productsApi';
import { TCreateProductAdminDto, TProduct } from '@/types/product.type';

type TProductsSlice = {
  isLoading: boolean;
  products: TProduct[];
  total: number;
  product: TProduct | null;
};

const initialState: TProductsSlice = {
  isLoading: false,
  products: [],
  total: 0,
  product: null
};

export const getProducts = createAsyncThunk(
  'admin/products',
  async ({
    limit,
    page,
    searchTerm,
    isActive,
    productType,
    bank
  }: {
    limit: number;
    page: number;
    searchTerm: string;
    isActive?: boolean;
    productType: string;
    bank: string;
  }) => {
    return productsApi.getAdminProducts(
      limit,
      page,
      searchTerm,
      productType,
      bank,
      isActive
    );
  }
);

export const createProduct = createAsyncThunk(
  'admin/products/create',
  async (data: TCreateProductAdminDto) => {
    return productsApi.createAdminProduct(data);
  }
);

export const updateProduct = createAsyncThunk(
  'admin/products/update',
  async ({
    request_id,
    data
  }: {
    request_id: string;
    data: TCreateProductAdminDto;
  }) => {
    return productsApi.updateAdminProduct(request_id, data);
  }
);

export const getProductById = createAsyncThunk(
  'admin/getProductById',
  async (productId: string) => {
    return productsApi.getAdminProductById(productId);
  }
);

export const adminProductsSlice = createSlice({
  name: 'admin/products',
  initialState: initialState,
  reducers: {
    resetProductState(state) {
      state.product = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getProducts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.product = action.payload;
      })
      .addCase(getProductById.pending, (state) => {
        state.isLoading = true;
        state.product = null;
      })
      .addCase(getProductById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetProductState } = adminProductsSlice.actions;
