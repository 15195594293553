import axios, { AxiosError, GenericAbortSignal } from 'axios';
import dayjs, { Dayjs } from 'dayjs';

import {
  TLeadsOptions,
  TLeadsPatch,
  TResponseLeads,
  TSingleLead
} from '@/components/App/Leads/types.ts';
import { ROUTES } from '@/components/Shared/routes/routes';
import { cleanPhoneNumber } from '@/services/formatter';
import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { getNavigate } from '@/services/helpers/navigate-helper';
import { getUserToken, setUserToken } from '@/services/user-hash';
import { setIsAuthErrorModal } from '@/store/auth.slice';
import { TDictContent } from '@/types/dicts';
import { InsuranceTableResponse } from '@/types/insuranceMapper';
import {
  GetInsuranceTableBody,
  TNewInsuranceServer
} from '@/types/insuranseServer';
import {
  LeasingParamsDto,
  TLeasingCreationDto,
  TLeasingEditDto
} from '@/types/leasing';
import { TNewInsuranceDto } from '@/types/newInsurance';
import { SystemNotificationResponse } from '@/types/notifications';
import { ChangeBankStatusInterface } from '@/types/offers';
import { TPromoProductModel } from '@/types/promotion.type';
import { TRoleAction } from '@/types/roleAction.type';

import { DictTypes } from '../constants';
import { encrypt } from '../services/encrypt';
import { store } from '../store';
import { TModalTSDto } from '../types/financing';
import { TNewOrderDto } from '../types/newOrder';
import { TCalculationSendBody } from '../types/productInfo';
import { TPaginationFilterDto } from '../types/request';
import {
  TAddProductDictionaryResponse,
  TDict,
  TProductInsuranceServerResponse
} from '../types/store';
import { TTemplateDto } from '../types/templates';
import { TUploadAdditionalFilesDto } from '../types/uploadFiles';

type CustomAxiosError<T = unknown> = AxiosError<T>;

export class ApiConnector {
  private token: string | undefined;
  private refresh: string | undefined;

  async call<Request, Response>({
    path,
    method = 'get',
    body,
    params,
    abortSignal
  }: {
    path: string;
    method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
    body?: Request;
    params?: Request;
    abortSignal?: GenericAbortSignal | undefined;
  }): Promise<Response> {
    const clonedParams = structuredClone(params);
    for (const param in clonedParams) {
      if (clonedParams[param] === undefined || !String(clonedParams[param]))
        delete clonedParams[param];
    }

    try {
      const { accessToken } = getUserToken();
      const { data } = await axios(
        `${process.env.REACT_APP_AUTH_URL}/` + path,
        {
          method,
          data: body,
          params: clonedParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          signal: abortSignal
        }
      );
      return data;
    } catch (e: unknown) {
      const error = e as CustomAxiosError<{
        message?: string;
        statusCode: number;
      }>;

      if (error?.response?.status === 401 || error?.response?.status === 403) {
        const navigate = getNavigate();
        clearLocalStorageHelper();
        const savedUrl =
          window.location.pathname !== ROUTES.login &&
          !window.location.pathname.includes('create')
            ? `${window.location.pathname}${window.location.search}`
            : '';
        navigate(ROUTES.login, { state: { savedUrl } });
      }

      const statusCode = error?.response?.data?.statusCode;
      let message = 'Произошла ошибка! Попробуйте позже';
      if (statusCode && statusCode !== 404 && statusCode < 500) {
        message = error?.response?.data?.message || message;
      }

      throw {
        message,
        statusCode,
        error: new Error(error.message || 'Неизвестная ошибка'), // TODO Выпилить, когда почистится по проекту
        isCancelled: axios.isCancel(e)
      };
    }
  }

  public async isValid() {
    try {
      const { accessToken, emailAllur } = getUserToken();
      const res = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/auth/validate`,
        method: 'post',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          email: emailAllur,
          accessToken
        }
      });

      const {
        data: { success }
      } = res;
      return success;
    } catch {
      return false;
    }
  }

  public async refreshToken(): Promise<void> {
    try {
      const { refreshToken: token, emailAllur } = getUserToken();
      const res = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/auth/refresh`,
        method: 'get',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`
        }
      });
      const {
        data: { accessToken, refreshToken }
      } = res;
      this.token = accessToken;
      if (accessToken !== 'undefined' && refreshToken !== 'undefined') {
        setUserToken({ accessToken, refreshToken, emailAllur });
      } else {
        clearLocalStorageHelper();
      }
    } catch {
      throw new Error('Refresh Token Expired! Logging out');
    }
  }

  public timeForCheck() {
    try {
      const navigate = getNavigate();
      const loginTime = localStorage.getItem('loginTime');
      if (!loginTime) {
        clearLocalStorageHelper();
        navigate(ROUTES.login);
        return;
      }

      const now = new Date().getTime();
      if (now > Number(loginTime)) {
        store.dispatch(setIsAuthErrorModal('reauthenticate'));
      }
    } catch {
      throw new Error('Refresh Token Expired! Logging out');
    }
  }

  public async authJwt(
    email: string,
    password: string,
    isRemember: boolean,
    ip: string,
    lat: string,
    long: string
  ): Promise<any> {
    try {
      const encData = encrypt(
        JSON.stringify({
          login: email,
          password: password,
          ip,
          lat,
          long
        })
      );
      const res = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/auth/login`,
        method: 'post',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        data: { data: encData }
      });
      const accessToken = res.data?.accessToken;
      const refreshToken = res.data?.refreshToken;
      this.token = accessToken;
      this.refresh = refreshToken;

      if (accessToken) {
        setUserToken({ accessToken, refreshToken, emailAllur: email });
        localStorage.setItem('isAuthenticated', 'true');
        const loginTime = isRemember
          ? dayjs().add(1, 'days').toDate().getTime()
          : dayjs().add(1, 'hours').toDate().getTime();
        localStorage.setItem('loginTime', String(loginTime));
      }
      return {
        status: !!accessToken,
        message: accessToken ? '' : res.data
      };
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  public async forgotePassword(email: string): Promise<any> {
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/auth/forgot_password`,
        method: 'post',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        data: {
          email: email
        }
      });

      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async resetPassword(password: string, token: string): Promise<any> {
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/auth/reset_password`,
        method: 'post',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        data: {
          password: password,
          token: token
        }
      });

      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async logout(): Promise<void> {
    try {
      await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Refresh-Token': this.refresh!
          }
        }
      );
    } catch (e: any) {
      throw new Error(e);
    } finally {
      this.token = undefined;
      this.refresh = undefined;
    }
  }

  public async postOrder(data: TNewOrderDto): Promise<any> {
    const path = `requests/create`;
    return await this.call({ path, method: 'post', body: data });
  }

  //DICTIONARY
  public async getOptimizedDictionaries(
    dictToFind: DictTypes[]
  ): Promise<TDict[]> {
    const path = `dictionaries/getBulk`;
    const objectToSend = {
      dicts: dictToFind
    };
    return await this.call({ path, method: 'post', body: objectToSend });
  }

  public async createNewSimpleDictItem(
    dictType: DictTypes,
    newDictDto: TDictContent
  ): Promise<any> {
    return await this.call({
      path: `admin/dicts/${dictType}/createItem`,
      method: 'post',
      body: newDictDto
    });
  }

  public async updateDict(dictType: string, data: TDictContent) {
    return await this.call({
      path: `admin/dicts/${dictType}/${data.uuid}/editItem`,
      method: 'patch',
      body: data
    });
  }

  public async getProductsDictionary(): Promise<
    TAddProductDictionaryResponse[]
  > {
    const path = 'requests/products/getProductsDict';
    return await this.call({ path });
  }

  public async getResponseByRequestId(id: string): Promise<any> {
    const path = `responses/${id}/findByRequestId`;
    return await this.call({ path });
  }

  public async getRequests(): Promise<any> {
    const userPosition = store.getState().userInfo?.userInfo?.position?.name;
    const path =
      userPosition === 'Директор' ? 'requests/director/all' : `requests`;
    return await this.call({ path });
  }
  public async getRequestsForPagination(
    paginationFilterDto: TPaginationFilterDto
  ): Promise<any> {
    // const userPosition = store.getState().userInfo?.userInfo?.position?.name;
    const path = 'requests/filters/director/all';
    // userPosition === 'Директор'
    //   ? 'requests/filters/director/all'
    //   : 'requests/filters/userRequests';
    return await this.call({ path, method: 'post', body: paginationFilterDto });
  }
  public async getRequestsCount() {
    const userPosition = store.getState().userInfo?.userInfo?.position?.name;
    const path =
      userPosition === 'Директор'
        ? 'requests/count/allCount'
        : 'requests/count/userCount';
    return await this.call({ path });
  }
  public async getUserReqsByStatus(
    status:
      | 'draft'
      | 'approved'
      | 'declined'
      | 'waiting_bank_response'
      | 'error'
      | 'financed'
      | 'cancelled'
      | 'waiting_of_financing'
      | 'accepted_alternative'
      | 'on_rework'
      | 'on_verification'
      | 'waiting_for_upload_files',
    dateDealerInfo: {
      dealer_uuid: string;
      date_from: string;
      date_to: string;
    }
  ): Promise<any> {
    const path = `requests/charts/${status}/getUserReqsByStatus`;
    return await apiConnector.call({
      path,
      method: 'post',
      body: dateDealerInfo
    });
  }

  public async getRequestById(reqId: string): Promise<any> {
    const path = `requests/${reqId}`;
    return await this.call({ path });
  }

  public async getOrderHistory<Response>(requestId: string): Promise<Response> {
    return await this.call({
      path: `responses/logs/surreal/history/${requestId}`
    });
  }

  public async getOfferHistory<Response>(
    offerId: string,
    bank: string
  ): Promise<Response> {
    return await this.call({
      path: `responses/logs/surreal/${bank}/${offerId}`
    });
  }

  public async reCreateRequest(
    reqId: string,
    banks: Record<string, { timeout: string; is_active: boolean }>
  ): Promise<any> {
    const path = `requests/restartKestra/${reqId}`;
    return await this.call({ path, method: 'post', body: banks });
  }

  public async getCities(code: string): Promise<
    {
      uuid: string;
      is_city: boolean | null;
      kato_code: string;
      name: string;
      parent_code: string;
      code: string;
    }[]
  > {
    const path = `dictionaries/cities/parent_code/${code}/findById`;
    return await this.call({ path });
  }

  public async acceptOffer(responseId: string, offerId?: string) {
    const path = `responses/${responseId}/accept`;
    return await this.call({ path, method: 'post', body: { offerId } });
  }

  public async changeOfferStatus(
    data: ChangeBankStatusInterface
  ): Promise<any> {
    const path = `responses/${data.bankId}`;
    const body = {
      is_offered: data.is_offered,
      offered_uuid: data.offered_uuid,
      status: data.status
    };
    return await this.call({ path, method: 'patch', body: body });
  }

  public async changeRequestStatus(data: {
    reqId: string;
    newStatus:
      | 'draft'
      | 'approved'
      | 'mobile_client'
      | 'declined'
      | 'waiting_bank_response'
      | 'error'
      | 'financed'
      | 'cancelled'
      | 'waiting_of_financing'
      | 'sms_verificating'
      | 'accepted_alternative'
      | 'waiting_for_upload_files';
  }): Promise<any> {
    const path = `requests/${data.reqId}`;
    const body = {
      status: data.newStatus
    };
    return await this.call({ path, method: 'patch', body: body });
  }

  public async getAttachedFilesByReqId(reqId: string) {
    const path = `files/${reqId}/findByRequestId`;
    return await this.call({ path, method: 'get' });
  }

  public async getUserAccount() {
    const path = 'users/userAccount';
    return await this.call({ path, method: 'get' });
  }

  public async getUserMenu(dealerId: string) {
    const path = `users/modules?dealerId=${dealerId}`;
    return await this.call({ path, method: 'get' });
  }

  public async getClientByIin(iin: string): Promise<{
    first_name: string;
    last_name: string;
    middle_name: string;
    phone_number: string;
    client_iin: string;
  } | null> {
    const path = `clients/iin/${iin}`;
    return await this.call({ path });
  }

  public async getClientById(id: string) {
    const path = `clients/${id}`;
    return await this.call({ path });
  }

  public async requestCreateUnverifiedDraft(data: {
    iin: string;
    clientPhone: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dealer_uuid: string;
    type: 'WhatsApp' | 'SMS';
  }): Promise<any> {
    const path = 'requests/createOTP';
    return await this.call({ path, body: data, method: 'post' });
  }

  public async downloadBankFile(uuid: string): Promise<any> {
    const path = `files/${uuid}/downloadmanually`;
    return await this.call({ path, method: 'get' });
  }

  public async clientCreateCalc(data: {
    iin: string;
    clientPhone: string;
    firstName: string;
    middleName: string;
    lastName: string;
    type: 'WhatsApp' | 'SMS';
  }) {
    const path = 'clients/createCalc';
    return await this.call({ path, body: data, method: 'post' });
  }

  public async createDraftFromCalc(clientCalcUuid: string) {
    const data = {
      client_calculation_uuid: clientCalcUuid
    };
    const path = 'requests/createDraftFromCalc';
    return await this.call({ path, body: data, method: 'post' });
  }

  public async validateOTP(data: { uuid: string; otp: string }) {
    const path = 'requests/validateOTP';
    return await this.call({ path, body: data, method: 'post' });
  }

  public async validateCalcOTP(data: { uuid: string; otp: string }) {
    const path = 'clients/validateOTP';
    return await this.call({ path, body: data, method: 'post' });
  }

  public async retryOTP(data: {
    uuid: string;
    clientPhone: string;
    type: 'WhatsApp' | 'SMS';
  }) {
    const path = 'requests/retryOTP';
    return await this.call({
      path,
      body: {
        request_uuid: data.uuid,
        clientPhone: data.clientPhone,
        type: data.type
      },
      method: 'post'
    });
  }

  public async getTemplates() {
    const path = 'utils/templates';
    return await this.call({ path });
  }

  public async redactTemplate(data: {
    templateId: string;
    template: TTemplateDto;
  }) {
    const path = `utils/templates/${data.templateId}/updateTemplate`;
    return await this.call({ path, body: data.template, method: 'patch' });
  }

  public async getDealersAll(): Promise<any[]> {
    const path = 'dictionaries/dealers/findAll';
    return await this.call({ path, method: 'get' });
  }

  public async getCitiesForFilter(type: 'all' | 'dealers') {
    const path = `requests/cities/getForFilter/${type}`;
    return await this.call({ path });
  }

  public async getAllRequestsForCharts(dealerId: string): Promise<any[]> {
    const path = `requests/charts/getAllForCharts?dealerId=${dealerId}`;
    return await this.call({ path });
  }

  public async getTopFc(dateValue: Dayjs): Promise<any[]> {
    const formattedStartDate = dateValue.startOf('month')?.format('YYYY-MM-DD');
    const fortmattedEndDate = dateValue
      .add(1, 'month')
      ?.startOf('month')
      ?.format('YYYY-MM-DD');
    const path = `requests/charts/getMonthlyEmpReqChart/${formattedStartDate}/${fortmattedEndDate}`;
    return await this.call({ path });
  }

  public async getApproxLoanAmount(data: TCalculationSendBody): Promise<any> {
    const path = 'requests/calculator/calcApproxLoanAmount';
    return await this.call({ path, method: 'post', body: data });
  }

  public async uploadAdditionalFiles(
    data: TUploadAdditionalFilesDto[]
  ): Promise<any> {
    const path = 'responses/files/uploadAdditionalFiles';
    return await this.call({ path, method: 'post', body: data });
  }

  public async refundRequest(responseId: string, returnLetter: string) {
    const path = `responses/${responseId}/refund`;
    return await this.call({ path, method: 'post', body: { returnLetter } });
  }

  // public async downloadAdditionalFiles(data: any[]): Promise<any> {
  //   const path = 'responses/files/downloadAdditionalFiles';
  //   return await this.call({
  //     path,
  //     method: 'post',
  //     body: data
  //   });
  // }

  public async getPromosByModel(modelId: string): Promise<any> {
    const path = `requests/products/getPromosByModel/${modelId}`;
    return await this.call({ path });
  }

  public async getProductInsurance(): Promise<
    TProductInsuranceServerResponse[]
  > {
    const path = 'requests/products/getProductInsurance';
    return await this.call({ path });
  }

  public async getResponseBodyLogs(responseId: string): Promise<any> {
    const path = `responses/logs/body_logs/${responseId}`;
    return await this.call({ path });
  }

  public async getEblankStatus(data: {
    requestId: string;
    responseExtUuid: string;
  }): Promise<any> {
    const path = `responses/eubank/${data.requestId}/${data.responseExtUuid}/findEubankStatus`;
    return await this.call({ path });
  }

  public async getForteStatus(extUuid: string): Promise<any> {
    const path = `responses/forte/${extUuid}/status`;
    return await this.call({ path });
  }

  public async updateVehicleInfo(data: TModalTSDto): Promise<any> {
    const path = 'requests/updateVehicleInfo';
    return await this.call({ path, method: 'post', body: data });
  }

  public async pnutUebankManually(responseId: string): Promise<any> {
    const path = `responses/files/${responseId}/downloadAdditionalFilesEubankMalually`;
    return await this.call({ path });
  }

  //DEALERS

  //BANKS

  //MODELS

  //PRODUCTS

  //Promo-Product-Model

  public async getAdminAllPromoProductModel(): Promise<{
    total: number;
    promo_product_models: TPromoProductModel[];
  }> {
    return await this.call({
      path: 'admin/promo-product-models/getAll',
      method: 'get'
    });
  }

  //CLIENTS

  //USERS

  //POSITIONS

  //SALE_POINTS

  //ROLES_ACTIONS

  public async getAdminRolesActions(): Promise<TRoleAction[]> {
    return await this.call({ path: `admin/roles_action/getAll` });
  }

  //LOGS

  //SQL

  public async createLeasingDraft(): Promise<any> {
    const path = 'leasings/create';
    return await this.call({ method: 'post', path });
  }

  public async updateLeasing(
    leasingUuid: string,
    leasingDto: TLeasingCreationDto
  ): Promise<any> {
    const path = `leasings/${leasingUuid}`;
    return await this.call({ body: leasingDto, method: 'patch', path });
  }

  public async downloadLeasing(id: string): Promise<any> {
    const path = `leasings/downloadLeasing/${id}`;
    return await this.call({ path });
  }

  public async getAllLeasings(params: LeasingParamsDto): Promise<any> {
    const queryParams = Object.entries(params)
      .map((item) => `${item[0]}=${item[1]}`)
      .join('&');
    const path = `leasings?${queryParams}`;
    return await this.call({ path });
  }

  public async updateLeasingStatus(id: string) {
    const path = `leasings/${id}`;
    return await this.call({ path, method: 'patch' });
  }

  public async getLeasingById(leasingId: string): Promise<any> {
    const path = `leasings/${leasingId}`;
    return await this.call({ path });
  }

  public async getLeasingFiles(leasingId: string): Promise<any> {
    const path = `leasings/${leasingId}/files`;
    return await this.call({ path });
  }

  public async getLeasingUpdateStatus(responseId: string): Promise<any> {
    const path = `leasings/responses/forte/${responseId}/status`;
    return await this.call({ path });
  }

  public async submitLeasingOfferForReview(offerId: string): Promise<any> {
    const path = `leasings/responses/${offerId}/submitForReview`;
    return await this.call({ path, method: 'post' });
  }

  public async approveLeasingOffer(offerId: string): Promise<any> {
    const path = `leasings/responses/${offerId}/approve`;
    return await this.call({ path, method: 'post' });
  }

  public async declineLeasingOffer(offerId: string): Promise<any> {
    const path = `leasings/responses/${offerId}/decline`;
    return await this.call({ path, method: 'post' });
  }

  public async editLeasingOffer(
    offerId: string,
    editLeasingDto: TLeasingEditDto
  ): Promise<any> {
    const path = `leasings/responses/${offerId}/edit`;
    return await this.call({ path, method: 'post', body: editLeasingDto });
  }

  public async generatePostcredOTP(data: any) {
    const body = {
      lastName: data.fullName.split(' ')[0] || ' ',
      firstName: data.fullName.split(' ')[1] || ' ',
      middleName: data.fullName.split(' ')[2] || ' ',
      iin: data.iin,
      telNum: cleanPhoneNumber(data.telNum)
    };
    const path = 'postcred/generateOTPWithDraft';
    return await this.call({ path, method: 'post', body: body });
  }

  public async createInsuranceOrder(
    data: TNewInsuranceDto
  ): Promise<TNewInsuranceServer> {
    return await this.call({
      path: `insurances/updateInsurance/${data.uuid}`,
      method: 'patch',
      body: data
    });
  }

  public async requestCreateOtpInsurance(data: {
    iin: string;
    clientPhone: string;
  }): Promise<any> {
    const path = 'insurances/createOTP';
    return await this.call({ path, body: data, method: 'post' });
  }

  public async validateOTPInsurance(data: { uuid: string; otp: string }) {
    const path = 'insurances/validateOTP';
    return await this.call({ path, body: data, method: 'post' });
  }

  public async getAllInsuranse(data: { any: object }) {
    const path = 'insurances/allForTable';
    return await this.call({ path, body: data, method: 'get' });
  }

  public async getOneInsurace(id: string) {
    const path = `insurances/findByInsuranceId/${id}`;
    return await this.call({ path, method: 'get' });
  }
  public async findByInsuranceId(id: string): Promise<any> {
    const path = `files/${id}/findByInsuranceId`;
    return await this.call({ path, method: 'get' });
  }

  // public async getFilterInsurance(data: ({telNumIin: string, userSearchType: string})) {
  //   const path = 'insurances/getFilteredInsurances'
  //   return await this.call({path, body: data, method: 'get'})
  //   }
  public async getFilterInsurance(
    data: GetInsuranceTableBody
  ): Promise<InsuranceTableResponse> {
    const path = 'insurances/filters/getFilteredInsurances';
    return await this.call({
      path,
      method: 'post',
      body: data
    });
  }

  public async cancelBankResponseV2(responseId: string) {
    const path = `responses/${responseId}/reject`;
    return await this.call({ path, method: 'post' });
  }

  public async cancelBankResponse(responseCancelData: {
    extId: string;
    responseId: string;
    requestId: string;
    bankName: string;
    userLogin: string;
  }) {
    const path = 'microservices/execService/decline_offer';
    return await this.call({ path, method: 'post', body: responseCancelData });
  }

  public async getFilterReqPersonal(
    bank_uuid?: string,
    status_code?: string,
    dealer_uuid?: string,
    model_uuid?: string,
    brand_uuid?: string,
    date_from?: string,
    date_until?: string
  ): Promise<{
    total: number;
    page_count: number;
    data: any;
  }> {
    let path = 'requests/filters/userRequestsByParams?';
    if (bank_uuid) {
      path = path + `&bank_uuid=${bank_uuid}`;
    }
    if (status_code) {
      path = path + `&status_code=${status_code}`;
    }
    if (dealer_uuid) {
      path = path + `&dealer_uuid=${dealer_uuid}`;
    }
    if (model_uuid) {
      path = path + `&model_uuid=${model_uuid}`;
    }
    if (brand_uuid) {
      path = path + `&brand_uuid=${brand_uuid}`;
    }
    if (date_from) {
      path = path + `&date_from=${date_from}`;
    }
    if (date_until) {
      path = path + `&date_until=${date_until}`;
    }
    return await this.call({ path });
  }

  public async getInsurancesByParams(
    status_code?: string,
    dealer_uuid?: string,
    model_uuid?: string,
    brand_uuid?: string,
    date_from?: string,
    date_until?: string
  ): Promise<{
    total: number;
    page_count: number;
    data: any;
  }> {
    let path = `insurances/filters/getInsurancesByParams?`;
    if (status_code) {
      path = path + `&status_code=${status_code}`;
    }
    if (dealer_uuid) {
      path = path + `&dealer_uuid=${dealer_uuid}`;
    }
    if (model_uuid) {
      path = path + `&model_uuid=${model_uuid}`;
    }
    if (brand_uuid) {
      path = path + `&brand_uuid=${brand_uuid}`;
    }
    if (date_from) {
      path = path + `&date_from=${date_from}`;
    }
    if (date_until) {
      path = path + `&date_until=${date_until}`;
    }
    return await this.call({ path });
  }

  startNotification(): Promise<{ payload: SystemNotificationResponse[] }> {
    return this.call({
      path: 'admin/notifications/start/all'
    });
  }

  startBadgeLeads(): Promise<number> {
    return this.call({
      path: 'leads/count/newCount'
    });
  }

  public async getLeads(
    searchOptionsDto: TLeadsOptions
  ): Promise<TResponseLeads> {
    const queryParams = new URLSearchParams();

    Object.entries(searchOptionsDto).forEach(([key, value]) => {
      if (value) {
        queryParams.append(key, value.toString());
      }
    });

    const queryString = queryParams.toString();
    const path = queryString ? `leads?${queryString}` : 'leads';
    return await this.call({ path, method: 'get' });
  }
  /**
   * Получить лид по идентификатору.
   * GET /leads/:requestId
   */
  public async getLeadById(requestId: string): Promise<TSingleLead> {
    const path = `leads/${requestId}`;
    return await this.call({ path, method: 'get' });
  }

  /**
   * Обновить лид по идентификатору.
   * PATCH /leads/:requestId
   *
   * @param requestId Идентификатор лида.
   * @param data Данные для обновления, типизированные как UpdateLeadsDto.
   */
  public async patchLead(
    requestId: string,
    data: TLeadsPatch
  ): Promise<unknown> {
    const path = `leads/${requestId}`;
    return await this.call({ path, method: 'patch', body: data });
  }
}

export const apiConnector = new ApiConnector();
