import './select.scss';

import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import ChevronDownIcon from '@/media/icons/chevron-down.svg?react';
import { debounce } from '@/services/helpers/debounce';

import type { IInputProps } from '../input';
import Input from '../input';
import OutsideClickHandler from '../outside-click-handler';
import useSelect from './useSelect';

export const SELECT_GROUP = 'select-group';

interface IProps extends Omit<IInputProps, 'onChange' | 'value'> {
  options: ISelectOptions[];
  selectedValue?: ISelectOption;
  onChange: (value: ISelectOption) => void;
}

const Select = forwardRef<HTMLInputElement, IProps>(
  (
    { selectedValue, options: propsOptions, onChange, isFrozen, ...rest },
    forwardRef
  ) => {
    const [topPosition, setTopPosition] = useState(false);
    const [focused, setFocused] = useState(false);
    const [focusTriggered, setFocusTriggered] = useState(false);

    const options: ISelectOption[] = useMemo(() => {
      return propsOptions.reduce((acc, option) => {
        if ('groupTitle' in option) {
          acc.push({ label: option.groupTitle, value: SELECT_GROUP });
          acc.push(...option.options);
          return acc;
        }

        acc.push(option);
        return acc;
      }, [] as ISelectOption[]);
    }, [propsOptions]);
    const hasOptionGroups = propsOptions.some(
      (option) => 'groupTitle' in option
    );

    const { bindInput, bindOptions, bindOption, suggestions, selectedIndex } =
      useSelect({
        selectedValue,
        onChange,
        defaultOptions: options,
        setFocused
      });

    const handleScroll = useCallback(
      debounce(() => {
        // Disable setting position when popup opened
        if (focused) return;

        if (bindOptions.ref.current) {
          const listTopPosition =
            bindOptions.ref.current.getBoundingClientRect().top;
          if (
            window.innerHeight - 260 < listTopPosition &&
            window.innerHeight - 260 > 260
          ) {
            setTopPosition(true);
          } else {
            setTopPosition(false);
          }
        }
      }, 300),
      [focused]
    );

    useEffect(() => {
      handleScroll();
      const main = document.querySelector('main');
      main?.addEventListener('scroll', handleScroll);
      return () => {
        main?.removeEventListener('scroll', handleScroll);
      };
    }, [bindOptions.ref, handleScroll]);

    const handlePopupToggle = () => {
      !isFrozen && setFocused((prev) => !prev);
    };

    const fieldClassName = [rest.className ?? '', 'select__field'].join(' ');

    return (
      <OutsideClickHandler onOutsideClick={() => setFocused(false)}>
        <div className="select">
          <Input
            {...rest}
            readOnly
            inputMode="none"
            ref={forwardRef}
            className={fieldClassName}
            value={bindInput.value ?? ''}
            autoComplete="off"
            endContent={
              <span
                className={`select__arrow ${
                  focused ? 'select__arrow--rotated' : ''
                }`}
              >
                <ChevronDownIcon />
              </span>
            }
            onChange={() => null}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && focused) e.preventDefault();
              if (e.key === 'Tab') setFocused(false);
              bindInput.onKeyDown(e);
              rest.onKeyDown?.(e);
            }}
            onFocus={(e) => {
              setFocusTriggered(true);
              handlePopupToggle();
              rest.onFocus?.(e);
            }}
            onClick={(e) => {
              setTimeout(() => {
                if (!focusTriggered) {
                  handlePopupToggle();
                }
                setFocusTriggered(false);
              }, 0);
              rest.onClick?.(e);
            }}
            isFrozen={isFrozen}
          />
          <div
            className={`select__popup ${
              focused ? 'select__popup--shown' : ''
            } ${topPosition ? 'select__popup--to-top' : ''}`}
          >
            <ul {...bindOptions} className="select__list">
              {suggestions.map((option, index) =>
                option.value === SELECT_GROUP ? (
                  <li
                    key={option.label}
                    style={{ padding: option.label ? undefined : '0' }}
                    className="select__group-item"
                  >
                    {option.label}
                  </li>
                ) : (
                  <li
                    className={`select__item ${
                      selectedIndex === index ? 'select__item--selected' : ''
                    } ${hasOptionGroups ? 'select__item--with-group' : ''}`}
                    key={option.label + option.value}
                    {...bindOption}
                  >
                    {option.label}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
);

Select.displayName = 'Select';

export default Select;

export type ISelectOptions = ISelectOption | ISelectGroup;

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ISelectGroup {
  groupTitle: string;
  options: ISelectOption[];
}
