import './style.scss';

import { Skeleton } from 'antd';
import React, {
  MouseEvent,
  ReactNode,
  createContext,
  useContext,
  useState
} from 'react';

import { useWindowSize } from '@/hooks/useWindowSize';
import Chevron from '@/media/icons/chevron.svg?react';
import EditIcon from '@/media/icons/edit.svg?react';
import InfoCircle from '@/media/svg/info-circle.svg?react';

import { Button, CircledImage, InfoBadge } from '..';
import { STATUSES_ENUM } from '../../constants/statuses';

interface OfferCardContextProps {
  isOpen: boolean;
  toggleOpen: () => void;
}

const OfferCardContext = createContext<OfferCardContextProps | undefined>(
  undefined
);
const useOfferCardContext = () => {
  const context = useContext(OfferCardContext);
  if (!context) {
    throw new Error('useOfferCardContext must be used within OfferCard');
  }
  return context;
};
interface HeaderActionInterface<T> {
  onClick: (value: T) => void;
  text: string | JSX.Element;
}

type OfferStatus = keyof typeof STATUSES_ENUM;

interface OfferCardProps {
  status?: OfferStatus;
  statusText?: string;
  isSelected?: boolean;
  extId?: string;
  children: ReactNode;
  className?: string;
  onEditStatus?: () => void;
}

const OfferCard: React.FC<OfferCardProps> & {
  Header: typeof Header;
  Body: typeof Body;
} = ({
  children,
  extId,
  status,
  statusText,
  isSelected,
  className,
  onEditStatus
}) => {
  const offerCardSelected = isSelected ? ` offer-card--selected` : '';
  const idStatusClass = status ? `offer-card__id--${status}` : '';
  const statusClass = status ? `offer-card__status--${status}` : '';

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const classes = [
    'offer-card',
    offerCardSelected,
    !isSelected && status === 'disabled' ? ' offer-card--disabled' : '',
    !isSelected && status === 'processing' ? ' offer-card--processing' : '',
    className ? ` ${className}` : ''
  ].join('');

  return (
    <OfferCardContext.Provider value={{ isOpen, toggleOpen }}>
      <div className={classes}>
        <div className="offer-card__left-absolute">
          {status && (
            <div
              role={onEditStatus ? 'button' : undefined}
              className={`offer-card__status ${statusClass}`}
              onClick={onEditStatus}
            >
              {statusText}
              {onEditStatus && <EditIcon />}
            </div>
          )}
        </div>
        {extId && (
          <div
            className={`offer-card__id ${idStatusClass}${offerCardSelected} `}
          >
            <p>{extId}</p>
          </div>
        )}
        {children}
      </div>
    </OfferCardContext.Provider>
  );
};

interface HeaderProps {
  icon?: string;
  title: string;
  status?: OfferStatus;
  isWaitingOffer?: boolean;
  info?: string;
  descriptionAction?: HeaderActionInterface<MouseEvent<HTMLButtonElement>>;
  headerActions?: JSX.Element[];
  defaultMobileView?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  icon,
  title,
  isWaitingOffer,
  info,
  status,
  descriptionAction,
  headerActions,
  defaultMobileView = true
}) => {
  const { isOpen, toggleOpen } = useOfferCardContext();
  const shouldShowChevron = !isWaitingOffer && status !== 'error';

  return (
    <div
      className="offer-header"
      onClick={shouldShowChevron ? toggleOpen : undefined}
    >
      <div className="offer-header__title-wrapper">
        {icon && <CircledImage src={icon} alt={title} size={32} />}
        <h2 className="offer-header__title">{title}</h2>
      </div>

      <div className="offer-header__actions">
        {descriptionAction && (
          <Button size="small" onClick={descriptionAction.onClick}>
            <InfoCircle /> {descriptionAction.text}
          </Button>
        )}
        {isWaitingOffer && (
          <Skeleton.Input active style={{ width: '187px', height: '20px' }} />
        )}
        {/* TODO -> сделать стили для info-badge display none */}
        {info && <InfoBadge title={info} />}

        <div
          className={`offer-header__actions-list ${
            defaultMobileView ? 'offer-header__actions-list--hidden' : ''
          }`}
        >
          {headerActions}
        </div>

        {shouldShowChevron && (
          <div
            className={`offer-header__chevron-mobile ${
              isOpen ? 'offer-header__chevron-mobile--active' : ''
            }`}
          >
            <Chevron />
          </div>
        )}
      </div>
    </div>
  );
};

interface BodyProps {
  additionalProducts?: {
    title: string | JSX.Element;
    content: string[];
  };
  footerActions?: JSX.Element[];
  products: {
    isRed?: boolean;
    title: string;
    description: string;
  }[];
}

const Body: React.FC<BodyProps> = ({
  additionalProducts,
  products,
  footerActions
}) => {
  const { isOpen } = useOfferCardContext();
  const [windowWidth] = useWindowSize();

  const mobileWidth = 860;

  const mainProducts =
    windowWidth <= mobileWidth ? products.slice(0, 3) : products;
  const mobileProducts =
    windowWidth <= mobileWidth && isOpen ? products.slice(3) : [];
  return (
    <div
      className={`offer-body ${isOpen ? 'offer-body--open' : 'offer-body--closed'}`}
    >
      <div
        className={`offer-body__product ${
          isOpen
            ? 'offer-body__product--visible'
            : 'offer-body__product--hidden'
        }`}
      >
        {mainProducts.map((product, index) => (
          <div
            className={`offer-body__product-item ${index > 2 && !isOpen}`}
            key={product.title}
          >
            <h4 className="offer-body__product-title">{product.title}</h4>
            <p
              className={`offer-body__product-description ${product.isRed ? 'offer-body__product-description--red' : ''}`}
            >
              {product.description}
            </p>
          </div>
        ))}
      </div>
      {mobileProducts.length > 0 &&
        mobileProducts.map((product, index) => (
          <div
            className={`offer-body__product-mobile-item ${
              index > 2 && !isOpen
                ? 'offer-body__product-mobile-item--hidden'
                : ''
            }`}
            key={product.title}
          >
            <h4 className="offer-body__product-mobile-title">
              {product.title}
            </h4>
            <p className="offer-body__product-mobile-description">
              {product.description}
            </p>
          </div>
        ))}

      {additionalProducts && (isOpen || windowWidth > mobileWidth) && (
        <div className="offer-body__additional">
          <h4 className="offer-body__additional-title">
            {additionalProducts.title}
          </h4>
          <ul className="offer-body__additional-list">
            {additionalProducts.content.map((content) => (
              <li className="offer-body__additional-item" key={content}>
                {content}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="offer-body__actions">{footerActions}</div>
    </div>
  );
};

OfferCard.Header = Header;
OfferCard.Body = Body;

export default OfferCard;
