import { TBank, TBankDto, TBankResponse } from '@/types/bank.type';

import { ApiConnector } from '../api.connector';

export class BanksApi extends ApiConnector {
  public getAdminAllBanks(
    limit: number,
    page: number,
    searchTerm: string
  ): Promise<{
    total: number;
    data: TBank[];
  }> {
    return this.call({
      path: 'admin/banks',
      params: { limit, page, searchTerm }
    });
  }

  public async getAdminBankById(bankId: string): Promise<TBankResponse> {
    return this.call({ path: `admin/banks/${bankId}` });
  }

  public async createAdminBank(data: TBankDto) {
    return await this.call({ path: `admin/banks`, body: data, method: 'post' });
  }

  public async updateAdminBank(bankId: string, data: TBankDto) {
    const res = await this.call({
      path: `admin/banks/${bankId}`,
      method: 'patch',
      body: data
    });
    return res;
  }
}

export const banksApi = new BanksApi();
