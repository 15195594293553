import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { OtpFormValues } from '../types/otp-form';

interface IProps {
  submitRequest: (values: OtpFormValues) => Promise<string>;
  onClose: () => void;
}

const useOtpForm = ({ submitRequest, onClose }: IProps) => {
  const methods = useForm<OtpFormValues, unknown>({
    mode: 'all',
    delayError: 200
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [otpError, setOtpError] = useState('');

  useEffect(() => {
    if (otpError) {
      methods.reset({
        first: undefined,
        second: undefined,
        third: undefined,
        fourth: undefined
      });

      methods.setError('fourth', { message: 'fourth' });
      methods.setError('third', { message: 'third' });
      methods.setError('second', { message: 'second' });
      methods.setError('first', { message: 'first' }, { shouldFocus: true });

      methods.setFocus('first');
    }
  }, [otpError]);

  const handleOtpFormSubmit = async (values: OtpFormValues) => {
    setSubmitLoading(true);
    const response = await submitRequest(values);
    if (!response) {
      onClose();
    } else {
      setOtpError(response);
    }
    setSubmitLoading(false);
  };

  return {
    otpFormMethods: methods,
    handleOtpFormSubmit,
    otpLoading: submitLoading,
    otpError,
    resetOtpError: () => setOtpError('')
  };
};

export default useOtpForm;
