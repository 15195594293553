import { LoadingOutlined } from '@ant-design/icons';
import { Radio, Spin } from 'antd';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Button, Input, MaskedInput } from '@/components/Shared/ui';
import AlertCircleIcon from '@/media/icons/alert-circle.svg?react';
import SmsMessageIcon from '@/media/icons/sms-message.svg?react';
import WhatsappIcon from '@/media/icons/whatsapp.svg?react';

import { ClientFormValues } from '../../types/client-form';

const options = [
  {
    label: (
      <>
        <WhatsappIcon />
        Whatsapp
      </>
    ),
    value: 'WhatsApp'
  },
  {
    label: (
      <>
        <SmsMessageIcon />
        SMS
      </>
    ),
    value: 'SMS'
  }
];

interface IProps {
  methods: UseFormReturn<ClientFormValues, unknown, undefined>;
  searchClientLoading: boolean;
  isClientFind: { fullName: boolean; phone: boolean };
  handleClose: () => void;
  clientFormLoading: boolean;
  clientError: boolean;
  handleClientFormSubmit: (values: ClientFormValues) => Promise<void>;
  hasWhatsApp: boolean;
}

const ClientForm: React.FC<IProps> = ({
  methods,
  searchClientLoading,
  // TODO Удалить, когда убедимся, что не будет жалоб
  // isClientFind,
  handleClose,
  clientFormLoading,
  clientError,
  handleClientFormSubmit,
  hasWhatsApp
}) => {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit
  } = methods;

  const phone = watch('phone');

  return (
    <form
      className="client-otp-modal__form"
      onSubmit={handleSubmit(handleClientFormSubmit)}
    >
      <Controller
        control={control}
        name="iin"
        rules={{
          required: 'Пожалуйста, введите данные',
          minLength: { value: 12, message: 'Неверный ИИН' }
        }}
        render={({ field }) => (
          <Input
            {...field}
            type="tel"
            label="ИИН клиента"
            value={field.value ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              if (value.match(/\D/) || value.length > 12) return;
              field.onChange(value);
            }}
            error={errors.iin?.message}
            endContent={
              searchClientLoading ? (
                <span className="client-otp-modal__iin-loading">
                  <Spin indicator={<LoadingOutlined spin />} />
                </span>
              ) : undefined
            }
            isRequired
          />
        )}
      />
      <Controller
        control={control}
        name="fullName"
        rules={{
          required: 'Пожалуйста, введите данные',
          minLength: { value: 1, message: 'Пожалуйста, введите данные' }
        }}
        render={({ field }) => (
          <Input
            {...field}
            label="ФИО клиента"
            value={field.value ?? ''}
            error={errors.fullName?.message}
            // TODO Удалить, когда убедимся, что не будет жалоб
            // isFrozen={isClientFind.fullName}
            isRequired
          />
        )}
      />
      <Controller
        control={control}
        name="phone"
        rules={{
          required: 'Пожалуйста, введите данные',
          validate: (value) => {
            if (!value.match(/\+\d \(\d\d\d\) \d\d\d-\d\d-\d\d/))
              return 'Неверный номер телефона';
          }
        }}
        render={({ field }) => (
          <MaskedInput
            {...field}
            type="tel"
            label="Номер телефона"
            value={field.value ?? ''}
            error={errors.phone?.message}
            // TODO Удалить, когда убедимся, что не будет жалоб
            // isFrozen={isClientFind.phone}
            mask="+7 (___) ___-__-__"
            isRequired
            autoComplete="off"
          />
        )}
      />

      {phone.length > 0 && !errors.phone && hasWhatsApp && (
        <div className="client-otp-modal__sent-with">
          <p className="client-otp-modal__sent-text">
            Отправить код верификации через:
          </p>
          <Controller
            control={control}
            name="sentWithType"
            render={({ field }) => (
              <Radio.Group block options={options} value={field.value} />
            )}
          />
        </div>
      )}

      {clientError && (
        <p className="client-otp-modal__error">
          <AlertCircleIcon />
          Произошла ошибка, пожалуйста попробуйте позже
        </p>
      )}

      <div className="client-otp-modal__actions">
        <Button type="button" onClick={handleClose}>
          Отмена
        </Button>
        <Button
          disabled={Object.values(errors).length !== 0}
          variant="filled-red"
          isLoading={clientFormLoading}
        >
          Далее
        </Button>
      </div>
    </form>
  );
};

export default ClientForm;
